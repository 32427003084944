const IconGondolaCold = () => {
    return (
        <>
            <svg width="102" height="142" viewBox="0 0 102 142" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_245_218)">
            <path d="M94.2886 142H7.71939C3.93379 142 0.848938 138.923 0.848938 135.13V6.87045C0.848938 3.0768 3.92574 0 7.71939 0H94.2886C98.0742 0 101.159 3.0768 101.159 6.87045V135.138C101.159 138.923 98.0823 142.008 94.2886 142.008V142ZM7.71939 2.41634C5.26278 2.41634 3.26527 4.41384 3.26527 6.87045V135.138C3.26527 137.594 5.26278 139.592 7.71939 139.592H94.2886C96.7453 139.592 98.7428 137.594 98.7428 135.138V6.87045C98.7428 4.41384 96.7453 2.41634 94.2886 2.41634H7.71939Z" fill="#183555"/>
            <path d="M98.7911 55.471H0.905304V57.8873H98.7911V55.471Z" fill="#183555"/>
            <path d="M98.7911 67.4641H0.905304V69.8804H98.7911V67.4641Z" fill="#183555"/>
            <path d="M27.042 57.4363H15.3147C13.4622 57.4363 11.948 55.8012 11.948 53.7957V32.8541C11.948 30.1881 13.9374 28.0134 16.3779 28.0134H17.4169V14.5141C17.4169 13.0321 18.5446 11.832 19.9299 11.832H22.4188C23.8041 11.832 24.9317 13.0321 24.9317 14.5141V28.0134H25.9708C28.4113 28.0134 30.4007 30.1881 30.4007 32.8541V53.7957C30.4007 55.8012 28.8945 57.4363 27.034 57.4363H27.042ZM16.3779 30.4297C15.2664 30.4297 14.3643 31.5171 14.3643 32.8541V53.7957C14.3643 54.4562 14.7992 55.02 15.3147 55.02H27.042C27.5575 55.02 27.9924 54.4562 27.9924 53.7957V32.8541C27.9924 31.5171 27.0903 30.4297 25.9788 30.4297H22.5235V14.5141C22.5235 14.345 22.4429 14.2564 22.4107 14.2483H19.9299C19.9299 14.2483 19.8333 14.345 19.8333 14.5141V30.4297H16.3779Z" fill="#183555"/>
            <path d="M57.0771 57.4363H45.3498C43.4973 57.4363 41.983 55.8012 41.983 53.7957V32.8541C41.983 30.1881 43.9725 28.0134 46.413 28.0134H47.452V14.5141C47.452 13.0321 48.5796 11.832 49.965 11.832H52.4538C53.8392 11.832 54.9668 13.0321 54.9668 14.5141V28.0134H56.0058C58.4463 28.0134 60.4358 30.1881 60.4358 32.8541V53.7957C60.4358 55.8012 58.9296 57.4363 57.069 57.4363H57.0771ZM46.413 30.4297C45.3015 30.4297 44.3994 31.5171 44.3994 32.8541V53.7957C44.3994 54.4562 44.8343 55.02 45.3498 55.02H57.0771C57.5926 55.02 58.0275 54.4562 58.0275 53.7957V32.8541C58.0275 31.5171 57.1254 30.4297 56.0139 30.4297H52.5585V14.5141C52.5585 14.345 52.478 14.2564 52.4458 14.2483H49.965C49.965 14.2483 49.8683 14.345 49.8683 14.5141V30.4297H46.413Z" fill="#183555"/>
            <path d="M87.1202 57.4363H75.3929C73.5404 57.4363 72.0261 55.8012 72.0261 53.7957V32.8541C72.0261 30.1881 74.0156 28.0134 76.4561 28.0134H77.4951V14.5141C77.4951 13.0321 78.6227 11.832 80.0081 11.832H82.4969C83.8823 11.832 85.0099 13.0321 85.0099 14.5141V28.0134H86.0489C88.4894 28.0134 90.4789 30.1881 90.4789 32.8541V53.7957C90.4789 55.8012 88.9727 57.4363 87.1121 57.4363H87.1202ZM76.4561 30.4297C75.3446 30.4297 74.4425 31.5171 74.4425 32.8541V53.7957C74.4425 54.4562 74.8774 55.02 75.3929 55.02H87.1202C87.6357 55.02 88.0706 54.4562 88.0706 53.7957V32.8541C88.0706 31.5171 87.1685 30.4297 86.057 30.4297H82.6016V14.5141C82.6016 14.345 82.5211 14.2564 82.4889 14.2483H80.0081C80.0081 14.2483 79.9114 14.345 79.9114 14.5141V30.4297H76.4561Z" fill="#183555"/>
            <path d="M52.3008 73.6177H49.8845V92.7229H52.3008V73.6177Z" fill="#183555"/>
            <path d="M58.7315 79.2279L50.5779 84.3427L51.8619 86.3896L60.0156 81.2749L58.7315 79.2279Z" fill="#183555"/>
            <path d="M43.3338 79.2359L42.0494 81.2826L50.2022 86.3988L51.4866 84.3521L43.3338 79.2359Z" fill="#183555"/>
            <path d="M52.3008 114.647H49.8845V133.752H52.3008V114.647Z" fill="#183555"/>
            <path d="M51.8588 120.967L50.5748 123.014L58.7284 128.129L60.0124 126.082L51.8588 120.967Z" fill="#183555"/>
            <path d="M50.2098 120.973L42.0562 126.088L43.3402 128.135L51.4938 123.02L50.2098 120.973Z" fill="#183555"/>
            <path d="M40.8877 108.029L24.3122 117.53L25.5138 119.626L42.0893 110.125L40.8877 108.029Z" fill="#183555"/>
            <path d="M27.1234 107.038L25.9856 109.17L34.4769 113.702L35.6147 111.57L27.1234 107.038Z" fill="#183555"/>
            <path d="M34.0165 112.9L33.6319 122.517L36.0463 122.614L36.4309 112.996L34.0165 112.9Z" fill="#183555"/>
            <path d="M76.4786 87.6335L59.9031 97.134L61.1047 99.2304L77.6801 89.7299L76.4786 87.6335Z" fill="#183555"/>
            <path d="M66.0045 84.7539L65.6199 94.3712L68.0343 94.4678L68.4189 84.8504L66.0045 84.7539Z" fill="#183555"/>
            <path d="M67.5843 93.6919L66.4465 95.8236L74.9378 100.356L76.0756 98.2239L67.5843 93.6919Z" fill="#183555"/>
            <path d="M26.644 86.0694L25.3107 88.0845L41.244 98.6266L42.5773 96.6114L26.644 86.0694Z" fill="#183555"/>
            <path d="M34.8646 83.8055L34.6328 93.4277L37.0484 93.4859L37.2802 83.8636L34.8646 83.8055Z" fill="#183555"/>
            <path d="M35.1411 92.6586L26.3771 96.6378L27.3761 98.8379L36.1401 94.8587L35.1411 92.6586Z" fill="#183555"/>
            <path d="M60.8553 108.701L59.522 110.716L75.4553 121.258L76.7887 119.243L60.8553 108.701Z" fill="#183555"/>
            <path d="M74.6518 108.589L65.8878 112.569L66.8867 114.769L75.6507 110.79L74.6518 108.589Z" fill="#183555"/>
            <path d="M64.9798 113.947L64.748 123.569L67.1637 123.628L67.3955 114.005L64.9798 113.947Z" fill="#183555"/>
            <path d="M51.2134 116.04L40.5332 109.871V97.5394L51.2134 91.3697L61.8936 97.5394V109.871L51.2134 116.04ZM42.9496 108.477L51.2134 113.246L59.4773 108.477V98.9328L51.2134 94.1646L42.9496 98.9328V108.477Z" fill="#183555"/>
            </g>
            <defs>
            <clipPath id="clip0_245_218">
            <rect width="100.302" height="142" fill="white" transform="translate(0.848938)"/>
            </clipPath>
            </defs>
            </svg>
        </>
    );
}

export default IconGondolaCold;