import axios from 'axios';
import { useEffect, useState } from 'react';
import { CircularProgress } from "@mui/material";
import 'App.scss';
import Menu from 'Components/DashboardDynamic/Menu';

import TabOne from 'Components/DashboardDynamic/TabOne';
import TabTwo from 'Components/DashboardDynamic/TabTwo';
import TabThree from 'Components/DashboardDynamic/TabThree';
import { useNavigate } from 'react-router-dom';
import TabCartelsImage from 'Components/DashboardDynamic/TabCartelsImage';
import TabBirthdays from 'Components/DashboardDynamic/TabBirthdays';
import { getUserToken } from 'Utils/UtilsDashboards';
import { Months } from 'Components/Data/Months';
import { ItemAppBar } from 'Components/Data/ItemAppBar';
import Footer from 'Components/Footer';

const DashboardDinamyc = () => {
    const navigate = useNavigate()
    const [configuration, setConfiguration] = useState({
        mounth: 0,
        year: 0
    })
    const [itemsAppBar, setItemsAppBar] = useState(ItemAppBar)
    const [totalData, setTotalData] = useState({
        soldsUsers: [],
        meta: "0",
        percentage: 0,
        sold: "0",
        orderSold: []
    })
    const [loader, setLoader] = useState(true)
    const [value, setValue] = useState("0");

    const [dataGraphicOne, setDataGraphicOne] = useState({
        lineData: [] as any[],
        data: [] as any[],
        textInfo: "",
        allData: [] as any[]
    })

    const [dataGraphicTwo, setDataGraphicTwo] = useState<any[]>([])
    const [dataGraphicThree, setDataGraphicThree] = useState<any[]>([])
    // eslint-disable-next-line 
    const [birthdays, setBirthdays] = useState<any>([])

    const [campaing, setCampaign] = useState<any>()

    const [dataAllBrands, setDataAllBrands] = useState<any[]>([])

    const [message, setMessage] = useState<any>(null)
    const [totalNumber, setTotalNumber] = useState(5)

    useEffect(() => {
        setTotalData({
            soldsUsers: [],
            meta: "0",
            percentage: 0,
            sold: "0",
            orderSold: []
        })
        const time = setTimeout(() => {

            getDataSettings()
        }, 1000);
        return () => clearTimeout(time);

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (configuration.mounth !== undefined && configuration.year !== 0) {
            getData()
            getCartelsImages()
        }
        // eslint-disable-next-line
    }, [configuration])

    // Funcion que aumenta los numeros
    useEffect(() => {
        const myInterval = setInterval(() => {
            setValue((prevTime) => {
                let number = Number(prevTime)
                if (number < totalNumber) {
                    number++
                    if ( number === 6 && birthdays.length === 0 ) number++
                } else {
                    number = 0
                    getData()
                }
                return number.toString()
            });
        }, 1000 * 60 * 2);
        return () => clearInterval(myInterval);
        // eslint-disable-next-line
    }, [totalNumber]);

    const getDataSettings = async () => {
        let user: any = localStorage.getItem("user");
        user = JSON.parse(user !== null ? user : "{}");
        let body = {
            token: user.token,
        }
        await axios.post(`${process.env.REACT_APP_URL}/settings/app/info/dashboard`, body).then((res) => {
            setConfiguration({
                mounth: res?.data?.data?.mounth !== undefined ? res.data.data.mounth : 0,
                year: res?.data?.data?.year !== undefined ? res.data.data.year : 0
            })
        }).catch((err) => {
            if (err.response.status === 401 || err.response.status === 403 || err.response.status === 404) {
                navigate("/login")
            }
        })
    }

    const getData = async () => {
        let body = {
            data: {
                year: configuration.year,
                month: configuration.mounth,
                calculateDetails: true,
                order: true
            }
        }
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `UserToken ${getUserToken()}`,
        }
        await axios.post(`${process.env.REACT_APP_URL}/sapuser/get/goals/vendor`, body, { headers: headers }).then((res) => {
            // Para el segundo grafico que se muestra
            let dataGraphicTwo: any[] = []
            // Para el primer grafico que se muestra
            let dataGraphicOne = {
                lineData: [] as any[],
                data: [] as any[],
                textInfo: "",
                allData: [] as any[]
            }
            // Informacion en general
            setTotalData({
                soldsUsers: res?.data?.data?.sold !== undefined ? res.data.data.sold : [],
                meta: res?.data?.data?.details?.meta !== undefined ? formatNumber(res.data.data.details.meta.toFixed(2)) : "0",
                percentage: res?.data?.data?.details?.percentage !== undefined ? res.data.data.details.percentage : 0,
                sold: res?.data?.data?.details?.sold !== undefined ? formatNumber(res.data.data.details.sold.toFixed(2)) : "0",
                orderSold: res?.data?.data?.details?.orderCanalSold !== undefined ? res.data.data.details.orderCanalSold : 0,
            })

            dataGraphicOne.lineData.push(["Vendedor", "Vendido", "Meta"])
            for (let i = 0; i < res?.data?.data?.details?.orderCanalSold.length; i++) {
                let meta = res?.data?.data?.details?.orderCanalSold[i].meta !== undefined ? res.data.data.details.orderCanalSold[i].meta : 0
                // let sold = res?.data?.data?.details?.orderCanalSold[i].sold !== undefined ? res.data.data.details.orderCanalSold[i].sold : 0
                let percentage = res?.data?.data?.details?.orderCanalSold[i].cumplimiento !== undefined ? res.data.data.details.orderCanalSold[i].cumplimiento : 0

                if (res.data.data.details.orderCanalSold[i].sold < res.data.data.details.orderCanalSold[i].meta) {
                    dataGraphicTwo.push({
                        type: res?.data?.data?.details?.orderCanalSold[i].vendor !== undefined ? res.data.data.details.orderCanalSold[i].vendor : "0",
                        percentage: percentage,
                        meta: percentage < 100 ? 100 - percentage : percentage
                    })
                } else {
                    dataGraphicTwo.push({
                        type: res?.data?.data?.details?.orderCanalSold[i].vendor !== undefined ? res.data.data.details.orderCanalSold[i].vendor : "0",
                        percentage: 100,
                        meta: 100
                    })
                }

                dataGraphicOne.lineData.push([
                    // res?.data?.data?.details?.orderCanalSold[i].vendor,
                    "",
                    meta,
                    percentage < 100 ? 100 - percentage : percentage
                ])
            }

            dataGraphicOne.data = [["Label", "Value"], ["%", res?.data?.data?.details?.percentage !== undefined ? res.data.data.details.percentage : 0]]
            dataGraphicOne.textInfo = `${res.data.data.details.percentage !== undefined ? formatNumber(res.data.data.details.percentage.toFixed(2)) : 0}% / 100.00%`
            dataGraphicOne.allData = res?.data?.data?.details?.orderCanalSold !== undefined ? res.data.data.details.orderCanalSold : []

            setDataGraphicOne(dataGraphicOne)
            setDataGraphicTwo(dataGraphicTwo)

        }).catch(() => { })

        await axios.post(`${process.env.REACT_APP_URL}/sapreport/get/sold/brand`, body, { headers: headers }).then((res) => {
            let data = [] as any[]
            let responseData = res?.data?.data !== undefined ? res.data.data : []

            data.push(["Marca", "Vendido"])
            for (let i = 0; i < responseData.length; i++) {
                data.push([
                    "",
                    responseData[i]?.sold !== undefined ? responseData[i].sold : 0
                ])
            }
            if (responseData.length > 17 && getUserToken() !== 'ZrYKHhaYJHe2qPpMvRgYLRYy7oo1') {
                responseData = responseData.slice(0, 17)
            }

            setDataAllBrands(responseData)
            setDataGraphicThree(data)
        }).catch((err) => {
            if (err.response.status === 401 || err.response.status === 403 || err.response.status === 404) {
                navigate("/signin")
            }
        })
    }

    const getCartelsImages = async () => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `UserToken ${getUserToken() ?? ""}`

        }
        await axios.get(`${process.env.REACT_APP_URL}/campaign/get/actually?id=actually`, { headers: headers }).then((response: any) => {
            let data = response.data.data
            setCampaign(data)
            let elementsItems = itemsAppBar
            if ( data.birthdays.length === 0 ) elementsItems.pop()
            setItemsAppBar(elementsItems)
            setBirthdays(() => {
                let info = data.birthdays !== undefined ? data.birthdays : []
                info = info.filter((item: any) => item.type !== "message")
                if ( info.length === 0 ) setItemsAppBar((prev:any) =>  prev.filter((item: any) => item.value !== "6"))
                return info
            })

            setMessage(() => {
                let info = data.birthdays !== undefined ? data.birthdays : []
                info = info.filter((item: any) => item.type === "message")
                if ( info.length === 0 ) setItemsAppBar((prev:any) =>  prev.filter((item: any) => item.value !== "7"))
                return info[0]
            })
            setTotalNumber(elementsItems.length - 1)
            setTimeout(() => {
                setLoader(false)
            }, 1000)
        }).catch((err: any) => {
            if (err.response?.status === 401 || err.response?.status === 403 || err.response?.status === 404) navigate("/signin")
        })
    }
    const formatNumber = (nStr: any) => {
        nStr += '';
        let x = nStr.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            // eslint-disable-next-line
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    }
    return (
        <>
            {
                loader ?
                    <div style={{  display: "grid", justifyContent: "center", alignContent: "center", height: "100vh" }}>
                        <CircularProgress sx={{ color: "#3b3b3b", fontWeight: "bold" }} />
                    </div>
                    :
                    <div className='content-index-root'>
                        <div className="app-bar-menu">
                            {
                                itemsAppBar.map((item:any, index:number) => {
                                    return (
                                        <div 
                                            key={index} 
                                            className={"app-bar-item " + (value === item.value ? "shadow-active" : "")}
                                            onClick={() => setValue(item.value)}>
                                                <img src={item.icon} alt={item.title} style={{ backgroundColor: item.color, padding:10 }}  />
                                                <div dangerouslySetInnerHTML={{ __html: item.title }}>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="index-dashboard-menu">
                            <div>
                                <Menu
                                    topSold={totalData.orderSold}
                                    sold={totalData.sold}
                                    month={Months[configuration.mounth - 1]}
                                    year={configuration.year}
                                />
                                <Footer />
                            </div>
                            <div>
                                { value ==="0" && dataGraphicOne.data.length > 0 && <TabOne {...dataGraphicOne} /> }
                                { value ==="1" && <TabTwo lineData={dataGraphicOne.lineData} data={dataGraphicTwo} /> }
                                { value ==="2" && <TabThree dataCiruclar={dataGraphicThree} dataTable={dataAllBrands} /> }
                                { value ==="3" && campaing?.images?.length > 0 && typeof campaing?.images[0]?.image === "string" && campaing?.images[0]?.image !== "" && <TabCartelsImage image={campaing?.images[0]?.image} type={campaing?.images[0]?.type}/> }
                                { value ==="4" && campaing?.images?.length > 0 && typeof campaing?.images[1]?.image === "string" && campaing?.images[1]?.image !== "" && <TabCartelsImage image={campaing?.images[1]?.image} type={campaing?.images[1]?.type}/> }
                                { value ==="5" && campaing?.images?.length > 0 && typeof campaing?.images[2]?.image === "string" && campaing?.images[2]?.image !== "" && <TabCartelsImage image={campaing?.images[2]?.image} type={campaing?.images[2]?.type}/> }
                                { value ==="6" && <TabBirthdays data={birthdays}/> }
                                { value ==="7" && message?.image?.length > 0 && typeof message?.image[0] === "string" && message?.image[0] !== "" && <TabCartelsImage image={message?.image[0]} type='mensaje'/> }

                                {/* { value ==="7" && <TabBirthdays data={birthdays}></TabBirthdays> } */}
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default DashboardDinamyc