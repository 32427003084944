import React from "react"

export interface User {
    email: string,
    token: string
    role: string
}

export interface Authentication {
    logged: boolean,
    user: User
}

export interface AuthContextModel {
    authState: Authentication,
    login: ( email: string, token: string, role: string) => void,
    logout: () => void
}

export interface PropsInterface {
    children: React.ReactElement
}

const UseContextProvider = React.createContext<AuthContextModel>({
    authState: {
        logged: false,
        user: {
            email: "",
            token: "",
            role: ""
        }
    },
    login: (email: string, token: string, role: string) => { },
    logout: () => { }
})

export default UseContextProvider;