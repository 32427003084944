import LogoBarlleno from "Media/Images/barlleno.png";
import LogoOhbi from "Media/Images/ohbi.png";
import LogoPagi from "Media/Images/pagi.png";
import LogoPremierTools from "Media/Images/premiertools.png";
import LogoBarllenoGO from "Media/Images/barllenogo.png";
import OnTrade from "Media/Images/on-trade-logo.png";

const Footer = ( ) => {
    const Icons = [
        {
            value: "0",
            icon: LogoPagi,
            title: "Pagi"
        },{
            value: "1",
            icon: LogoOhbi,
            title: "Ohbi"
        },{
            value: "2",
            icon: LogoBarlleno,
            title: "Barlleno"
        },{
            value: "3",
            icon: LogoPremierTools,
            title: "Premier Tools"
        },{
            value: "4",
            icon: OnTrade,
            title: "OnTrade"
        },{
            value: "5",
            icon: LogoBarllenoGO,
            title: "Barlleno GO"
        }
    ]
    return( 
        <div className="footer">
            <div className="icons-footer">
                {
                    Icons.map((item, index) => {
                        return (
                            <img key={index} src={item.icon} alt={item.title} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Footer