const IconInventory = () => {
    return (
        <>
            <svg width="98" height="142" viewBox="0 0 98 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_240_161)">
                <path d="M48.9959 2.44617C52.8935 2.44617 56.0572 5.61803 56.0572 9.50744H58.5033C58.5033 4.25633 54.247 0 48.9959 0C43.7448 0 39.4885 4.25633 39.4885 9.50744H41.9346C41.9346 5.60988 45.1065 2.44617 48.9959 2.44617Z" fill="#183555"/>
                <path d="M91.6408 14.0818H74.3545V16.4301C74.3545 16.4301 74.3545 16.4953 74.3545 16.5279H91.6408C93.4835 16.5279 94.9839 18.0282 94.9839 19.871V136.219C94.9839 138.062 93.4835 139.562 91.6408 139.562H6.35921C4.51643 139.562 3.01611 138.062 3.01611 136.219V19.8629C3.01611 18.0201 4.51643 16.5198 6.35921 16.5198H23.6455C23.6455 16.5198 23.6455 16.4545 23.6455 16.4219V14.0736H6.35921C3.16288 14.0736 0.569946 16.6665 0.569946 19.8629V136.211C0.569946 139.407 3.16288 142 6.35921 142H91.6408C94.8371 142 97.43 139.407 97.43 136.211V19.8629C97.43 16.6665 94.8371 14.0736 91.6408 14.0736V14.0818Z" fill="#183555"/>
                <path d="M71.6556 9.50745H26.3444C24.8522 9.50745 23.6373 10.7142 23.6373 12.2145V16.4301C23.6373 16.4301 23.6373 16.4953 23.6373 16.5279C23.6862 20.0667 26.5645 22.9124 30.1115 22.9124H67.864C71.4109 22.9124 74.2893 20.0586 74.3382 16.5279C74.3382 16.4953 74.3382 16.4627 74.3382 16.4301V12.2145C74.3382 10.7224 73.1314 9.50745 71.6311 9.50745H71.6556ZM71.9165 14.0736V16.4219C71.9165 16.4219 71.9083 16.4872 71.9083 16.5198C71.8594 18.6969 70.0737 20.4581 67.8803 20.4581H30.1278C27.9344 20.4581 26.1568 18.6969 26.0998 16.5198C26.0998 16.4872 26.0916 16.4546 26.0916 16.4219V12.2064C26.0916 12.0678 26.2058 11.9455 26.3525 11.9455H71.6556C71.7942 11.9455 71.9165 12.0596 71.9165 12.2064V14.0736Z" fill="#183555"/>
                <path d="M81.693 50.179H34.7837V52.6252H81.693V50.179Z" fill="#183555"/>
                <path d="M19.9599 53.1308L15.4426 48.6135L17.1713 46.8849L19.9599 49.6735L27.6246 42.0089L29.3532 43.7375L19.9599 53.1308Z" fill="#183555"/>
                <path d="M81.693 73.2056H34.7837V75.6518H81.693V73.2056Z" fill="#183555"/>
                <path d="M19.9599 76.1573L15.4426 71.6319L17.1713 69.9033L19.9599 72.6919L27.6246 65.0354L29.3532 66.7641L19.9599 76.1573Z" fill="#183555"/>
                <path d="M81.693 96.2241H34.7837V98.6702H81.693V96.2241Z" fill="#183555"/>
                <path d="M19.9599 99.1758L15.4426 94.6585L17.1713 92.9299L19.9599 95.7185L27.6246 88.0539L29.3532 89.7825L19.9599 99.1758Z" fill="#183555"/>
                <path d="M81.693 119.251H34.7837V121.697H81.693V119.251Z" fill="#183555"/>
                <path d="M19.9599 122.202L15.4426 117.685L17.1713 115.956L19.9599 118.745L27.6246 111.08L29.3532 112.809L19.9599 122.202Z" fill="#183555"/>
                </g>
                <defs>
                <clipPath id="clip0_240_161">
                <rect width="96.8601" height="142" fill="white" transform="translate(0.569946)"/>
                </clipPath>
                </defs>
            </svg>
        </>
    );
}

export default IconInventory;