import { useContext } from 'react';
import UseContextProvider from "Interface/IAuthContext";

const NavbarMenu = () => {
    // context
    const context = useContext(UseContextProvider);

    return (
        <>
            <div className="bg-navbar flex items-center justify-between p-4 ">
                <h2 className="text-white text-2xl">Premier <span className="font-semibold">Dashboard</span></h2>
                <div>
                    <button
                        className='bg-red-500 hover:bg-red-800 font-semibold px-2 py-1 rounded-lg text-white'
                        onClick={context.logout}>Cerrar sesión
                    </button>
                </div>
            </div>
        </>
    )
}

export default NavbarMenu;