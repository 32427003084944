import { types } from 'Types/Types';

export const authReducer = ( state: any, action: any ) => {
    switch ( action.type ) {
        case types.login:
            return {
                ...state,
                logged: true,
                user: action.paylod
            };
        case types.logout:
            return {
                logged: false
            };   
        default:
            return state;
    }
}   