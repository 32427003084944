import { Route, Routes } from 'react-router-dom';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import Login from 'pages/Login';
import { DashboardsRoutes } from 'routes/DashboardsRoutes';

export const AppRouter = () => {
  return (
    <>
        <Routes>           
            <Route path="/login" element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
                      
            <Route path="/*" element={
              <PrivateRoute>
                <DashboardsRoutes />
              </PrivateRoute>
            } />
        </Routes>
    </>
  )
}