import styled from 'styled-components'
import BurgerButton from "./BurgerButton";

const NavbarDashboards = ( {clicked, handlerClick, dates}: {clicked: any, handlerClick: any, dates: string}) => {
    return (
        <>
            <NavContainer>
                <div className="bg-navbar flex flex-col items-center p-4 2xl:flex-row 2xl:justify-between">
                    <div className='flex w-full items-center justify-between'>
                        <h2 className="text-white text-2xl">Premier <span className="font-semibold">Dashboard</span></h2>
                        <div className="burger">
                            <BurgerButton clicked={ clicked } handlerClick={ handlerClick } />
                        </div>
                    </div>
                    
                    <div className='flex w-full 2xl:w-1/2 2xl:flex-end 2xl:justify-end'>
                        <p className='text-white text-lg font-semibold'>{dates}</p>
                    </div>
                </div>
            </NavContainer>
            <BgDiv className={`initial ${clicked ? ' active' : ''}`}></BgDiv>
        </>
    )
}

export default NavbarDashboards;

const NavContainer = styled.nav
`
.burger{
    @media(min-width: 1536px){
        display: none;
    }
}
`

const BgDiv = styled.div`
    background-color: #222;
    position: absolute;
    top: -1700px;
    left: -1700px;
    width: 100%;
    height: 100%;
    transition: all .6s ease ;
    z-index: 2;
    @media(min-width: 1532px){
        display: none;
    }
    &.active{
        border-radius: 0 0 70% 0;
        top: 115px;
        left: 0;
        width: 100%;
        height: 100%;
    }
`