import { Pagination } from '@mui/material';
import ImageVisualizerModal from 'Components/ImageVisualizerModal/ImageVisualizerModal';
import { useState } from 'react';

const ReportCardGondolaCold = ( {report, pagination, changePagination }: {report: any, pagination: any, changePagination: any} ) => {
    const [showImageModal, setShowImageModal] = useState(false);
    const [images, setImages] = useState<any[]>([]);

    const onShowImageHandler = (images: any[]) => {
        setImages(images);
        setShowImageModal(!showImageModal);
    }

    return (
        <>
            {
                report.map((row: any, i: number) => (
                    i >= (30 * pagination - 30) && i < (30 * pagination) ?
                        <div key={i} className='bg-white flex flex-col justify-center p-4 rounded-lg shadow [&>div>span]:font-semibold'>
                            <div><span>Display:</span> {row.name}</div>
                            <div><span>Fecha de creación:</span> {row.created}</div>
                            <div><span>Fecha de inicio:</span> {row.createdComplete}</div>
                            <div><span>Fecha de finalización:</span> {row.finishedAt} </div>
                            <div><span>Sucursal:</span> {row.nameSucursal}</div>
                            <div><span>Sección:</span> {row.section}</div>
                            <div><span>Subsección:</span> {row.subSectiion}</div>
                            {
                                row.image === "" ? <div></div> :
                                    <div className='mt-2'> 
                                        <button 
                                            className='bg-gray-200 hover:bg-gray-300 font-semibold px-2 py-1 rounded-lg' 
                                            onClick={() => onShowImageHandler(row.image)}>
                                                Ver imagen
                                        </button>                                       
                                    </div>
                            }
                        </div>
                        :
                        <div key={i} style={{ display: "none" }}></div>
                ))
            }
            <div className='mt-3 mb-3 flex justify-center'>
                <Pagination size="large" color="primary" count={Math.ceil(report.length / 30)} defaultPage={1} siblingCount={0} onChange={changePagination} />
            </div>
            <ImageVisualizerModal modalState={showImageModal} changeModalState={setShowImageModal} images={images} />
        </>
    )
}

export default ReportCardGondolaCold;