import { useContext, useState } from "react";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";

//Material UI
import {
    Button,
    InputAdornment,
    IconButton,
    Backdrop,
    CircularProgress,
} from "@mui/material";

//Componentes
import { CssTextField } from "Components/DashboardDynamic/CssTextField"
import SnackBar from "Components/DashboardDynamic/SnackBar";
//Iconos
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//Imagenes
import Logo from "Media/Login/Logo.png"
import "App.scss"
import axios from "axios";
import UseContextProvider from 'Interface/IAuthContext';

const Login = () => {
    const navigate = useNavigate();
    const context = useContext( UseContextProvider );
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [errorCredentials, setErrorCredentials] = useState(false)
    const [ loader, setLoader ] = useState(false)

    // Método que recibe la informacion
    const sendDataLogin = async (dataForm: any) => {
        let data = {
            ...dataForm,
            email: dataForm.email.replaceAll(" ", "")
        }
        console.log(data)
        setLoader(true)
        // await axios.post(`http://localhost:3000/authenticate/auth/credentials`, { data: data }).then((response) => {
        await axios.post(`${ process.env.REACT_APP_URL }/authenticate/auth/credentials`, { data: data }).then((response) => {
            if (response.data.status === 200) {
                const email = dataForm.email.split(" ")[0];
                const token = response.data.data !== undefined ? (response.data.data + "") : ""               
                context.login(email, token, "");
                setLoader(false)
                navigate("/menu")
            } else {
                setErrorCredentials(true);
                setLoader(false);
            }
            
        }).catch((err) => {
            setErrorCredentials(true);
            setLoader(false);
        })
    }

    // Cambia la visibilidad de la contraseña
    const changeVisibility = () => {
        setShowPassword(!showPassword)
    }

    return (
        <>
            <div className="rootLogin">
                <Backdrop
                    sx={{ color: '#ffffff', zIndex: (theme:any) => theme.zIndex.drawer + 1 }}
                    open={loader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <SnackBar open={errorCredentials} title="Usuario o contraseñas no validos" type="error" setOpen={setErrorCredentials} />
                <div style={{ display: 'felx', justifyContent: 'center', textAlign: "center" }}>
                    <img style={{ display: 'block', margin: '60px auto', aspectRatio: '1/1', maxWidth: '200px' }} src={Logo} alt="Logo de la aplicación" />
                </div>
                <div style={{ width: '80%', margin: '-30px auto', textAlign: "center" }}>
                    <form onSubmit={handleSubmit(sendDataLogin)}>
                        <CssTextField
                            error={errors?.email ? true : false}
                            label="Correo electronico"
                            autoComplete="off"
                            style={{
                                color: "white",
                                marginTop: "50px",
                                borderRadius: "8px",
                                width: "40%",
                                minWidth: "300px",
                                backgroundColor: "white"
                            }}
                            {...register("email", {
                                required: {
                                    value: true,
                                    message: "Ingrese su correo"
                                }
                            })}
                        ></CssTextField>
                        <br />
                        <CssTextField
                            error={errors?.password ? true : false}
                            // helperText={errors.password?.message}
                            label="Contraseña" style={{
                                marginTop: "35px",
                                color: "white",
                                borderRadius: "8px",
                                width: "40%",
                                minWidth: "300px",
                                backgroundColor: "white"
                            }}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={changeVisibility}>
                                            {showPassword ? <VisibilityOffIcon style={{ color: "#173456" }} /> : <VisibilityIcon style={{ color: "#173456" }} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            {...register("password", {
                                required: {
                                    value: true,
                                    message: "Debe de ingresar su contraseña"
                                }
                            })}
                        />
                        <br />
                        <Button className="buttonLogin" variant="text" style={{
                            backgroundColor: "#173456",
                            color: 'white',
                            minWidth: "300px",
                            width: "40%",
                            marginTop: "60px"
                        }} type="submit" ><p style={{ fontWeight: "bold" }}>Iniciar sesión</p></Button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Login