import AuthContextProvider from "Context/AuthContext";
import { AppRouter } from "Router/AppRouter";

const App = () => {
	return (
		<AuthContextProvider>
			<AppRouter />
		</AuthContextProvider>
	)
}

export default App;
