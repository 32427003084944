import ControlVendor from 'pages/ControlVendor';
import DashboardDinamyc from 'pages/DashboardDinamyc';
import DashboardGondolaCold from 'pages/DashboardGondolaCold';
import DashboardInventory from 'pages/DashboardInventory';
import DashboardOrders from 'pages/DashboardOrders';
import MenuDashboards from 'pages/MenuDashboards';
import { Navigate, Route, Routes } from 'react-router-dom';

export const DashboardsRoutes = () => {
  return (
    <>
      <div>
        <Routes>
          <Route path="/menu" element={<MenuDashboards />} />
          <Route path="/dashboard" element={<DashboardDinamyc />} />
          <Route path="/dashboardGondolaFrio" element={<DashboardGondolaCold />} />
          <Route path="/dashboardPedidos" element={<DashboardOrders />} />
          <Route path="/dashboardInventario" element={<DashboardInventory />} />
          <Route path="/vendor/control" element={<ControlVendor />} />
          <Route path="/*" element={<Navigate to="/menu" />} />
        </Routes>
      </div>
    </>
  )
}