import { Pagination } from '@mui/material';
import { useState } from 'react';
import ImageVisualizerModal from 'Components/ImageVisualizerModal/ImageVisualizerModal';

const ReportTableOrders = ( {report, pagination, changePagination }: {report: any, pagination: any, changePagination: any} ) => {
    const [showImageModal, setShowImageModal] = useState(false);
    const [images, setImages] = useState<any[]>([]);

    const onShowImageHandler = (images: any[]) => {
        setImages(images);
        setShowImageModal(!showImageModal);
    }

    return (
        <>
            <table className='w-full border-r-2 border-l-2 border-gray-100'>
                <thead className='border-b-2 border-gray-200'>
                    <tr>
                        <th className='p-3 text-sm font-semibold tracking-wide text-left'>Display</th>
                        <th className='p-3 text-sm font-semibold tracking-wide text-left'>Fecha</th>
                        <th className='p-3 text-sm font-semibold tracking-wide text-left'>Sucursal</th>
                        <th className='p-3 text-sm font-semibold tracking-wide text-left'>Imagen</th>
                    </tr>
                </thead>
                <tbody className=''>
                    {
                        report.map((row: any, i: number) => (
                            i >= (30 * pagination - 30) && i < (30 * pagination) ?
                                <tr key={i} className='bg-white cursor:pointer [&>td]:p-3 text-sm text-gray-700 whitespace-nowrap'>
                                    <td> {row.name} </td>
                                    <td> {row.created} </td>
                                    <td> {row.nameSucursal} </td>
                                    {
                                        row.orderImage === "" ? <td></td> :
                                            <td>
                                                <button
                                                    className='bg-gray-200 hover:bg-gray-300 font-semibold px-2 py-1 rounded-lg'
                                                    onClick={() => onShowImageHandler(row.orderImage)}>Ver imagen
                                                </button>
                                            </td>
                                    }
                                </tr>
                                :
                                <tr key={i} style={{ display: "none" }}></tr>
                        ))
                    }
                </tbody>
            </table>
            <div className='mt-3 mb-3 flex justify-center'>
                <Pagination size="large" color="primary" count={Math.ceil(report.length / 30)} defaultPage={1} page={pagination} siblingCount={0} onChange={changePagination} />
            </div>
            <ImageVisualizerModal modalState={showImageModal} changeModalState={setShowImageModal} images={images} />
        </>
    );
}

export default ReportTableOrders;