import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CircularProgress } from '@mui/material';
import { getFilterValues, getUserToken } from 'Utils/UtilsDashboards';
import { getInventoryReport } from 'Services/InventoryService';
import NavbarDashboards from 'Components/NavbarDashboards/NavbarDashboards';
import MenuOptionsDashboards from 'Components/MenuOptionsDashboards/MenuOptionsDashboards';
import { getDisplaysByDate, getSucursalByDateAndDisplay, getProductsByDateAndDisplayAndSucursal } from 'Utils/DashboardInventory/UtilsDashboardInventory'

const DashboardInventory = () => {
    // states that help us to show the report
    const [reportDates, setReportDates] = useState("");

    // states that help us to show the options in the filters
    const [displays, setDisplays] = useState<any>([]);
    const [dates, setDates] = useState<any>([]);
    const [sucursals, setSucursals] = useState<any[][]>([]);
    const [products, setProducts] = useState<any[][][]>([]);

    // loader
    const [isLoading, setIsLoading] = useState(true);

    // state that help us to know if we have to show the hamburguer menu option
    const [clicked, setClicked] = useState(false);

    const handlerClick = () => {
        setClicked(!clicked);
    }

    // function to obtain the report from the API and filter's options
    const getReport = async () => {
        // obtains user's token
        const userToken = getUserToken();

        // obtains inventory report
        const report = await getInventoryReport(userToken);

        // obtains dates in the report
        const datesArray = getFilterValues(report, "created");
        setDates(datesArray);

        const reportDates = "Del " + datesArray[0] + " al " + datesArray[datesArray.length - 1];

        // obtains display for every date
        const displaysArray: any = [];

        datesArray.forEach((date: any, indexDate: number) => {
            displaysArray.push(getDisplaysByDate(report, date, datesArray, indexDate));
        })

        setDisplays(displaysArray);

        // obtains sucursals for every display in every date
        const sucursalsArray: any[][] = [];

        for (let i = 0; i < datesArray.length; i++) {
            sucursalsArray.push(getSucursalByDateAndDisplay(report, datesArray[i], displaysArray[i]));
        }

        setSucursals(sucursalsArray);

        // obtains products for every sucursal for every display in every date
        const productsArray: any[][][] = [];

        for (let i = 0; i < datesArray.length; i++) {
            productsArray.push(getProductsByDateAndDisplayAndSucursal(report, datesArray[i], displaysArray[i], sucursalsArray[i]));
        }

        setProducts(productsArray);

        setReportDates(reportDates);

        // set the value of false to the variable that controls the loading screen 
        setIsLoading(false);
    }

    useEffect(() => {
        getReport();
    }, [])


    return (
        <>
            {
                isLoading ?
                    <div style={{ display: "grid", justifyContent: "center", alignContent: "center", height: "100vh" }}>
                        <CircularProgress sx={{ color: "#3b3b3b", fontWeight: "bold" }} />
                    </div>
                    :
                    <div>
                        <NavbarDashboards clicked={clicked} handlerClick={handlerClick} dates={reportDates} />
                        <div className='w-full p-2 2xl:flex flex-row-reverse justify-between 2xl:p-0'>
                            <div className='grid grid-cols-1 gap-4 w-full 2xl:w-[88%]'>
                                {
                                    dates.map((date: any, indexDate: number) => (
                                        <Accordion key={'date_' + indexDate} sx={{ boxShadow: 'none;' }}>
                                            <AccordionSummary
                                                sx={{ display: 'flex', flexDirection: 'row-reverse' }}
                                                expandIcon={<ArrowDropDownIcon sx={{ color: '#000', width: '40px', height: '40px' }} />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography sx={{ width: '100%', textAlign: 'start', display: 'flex', justifyContent: 'space-between' }} component={'div'}>
                                                    <p>{date.value}</p>
                                                    <p className='font-semibold'>{date.quantityDate}</p>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {
                                                    displays[indexDate].map((display: any, indexDisplay: number) => (
                                                        <Accordion key={date + '_display_' + indexDisplay} sx={{ boxShadow: 'none;' }}>
                                                            <AccordionSummary
                                                                sx={{ display: 'flex', flexDirection: 'row-reverse' }}
                                                                expandIcon={<ArrowDropDownIcon sx={{ color: '#000', width: '40px', height: '40px' }} />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <Typography sx={{ width: '100%', textAlign: 'start', display: 'flex', justifyContent: 'space-between' }} component={'div'}>
                                                                    <p>{display.name}</p>
                                                                    <p className='font-semibold'>{display.quantity}</p>
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                {
                                                                    sucursals[indexDate][indexDisplay].map((sucursal: any, indexSucursal: number) => (
                                                                        <Accordion key={date + '_display_' + indexDisplay + '_sucursal_' + indexSucursal} sx={{ boxShadow: 'none;' }}>
                                                                            <AccordionSummary
                                                                                sx={{ display: 'flex', flexDirection: 'row-reverse' }}
                                                                                expandIcon={<ArrowDropDownIcon sx={{ color: '#000', width: '40px', height: '40px' }} />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header"
                                                                            >
                                                                                <Typography sx={{ width: '100%', textAlign: 'start', display: 'flex', justifyContent: 'space-between' }} component={'div'}>
                                                                                    <p>{sucursal.name}</p>
                                                                                    <p className='font-semibold'>{sucursal.quantity}</p>
                                                                                </Typography>

                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                {
                                                                                    products[indexDate][indexDisplay][indexSucursal].map((product: any, indexProduct: number) => (
                                                                                        <div
                                                                                            key={date + '_display_' + indexDisplay + '_sucursal_' + indexSucursal + '_product_' + indexProduct}
                                                                                            className='flex justify-between border-b-2 last:border-b-0'
                                                                                        >
                                                                                            <p className='text-left'>{product.name}</p>
                                                                                            <p className='text-right font-semibold'>{product.quantity}</p>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    ))
                                                                }
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                }
                            </div>

                            <MenuOptionsDashboards clicked={clicked} gondola={false} orders={false} inventory={true} />
                        </div>
                    </div>
            }
        </>
    )
}

export default DashboardInventory;