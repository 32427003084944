import { Accordion, AccordionDetails, AccordionSummary, Pagination } from '@mui/material';
import { useState } from 'react';
import ImageVisualizerModal from 'Components/ImageVisualizerModal/ImageVisualizerModal';
import { IonIcon } from '@ionic/react';
import { caretDown } from 'ionicons/icons';

const ReportTableGondolaCold = ({ report, pagination, changePagination }: { report: any, pagination: any, changePagination: any }) => {
    const [showImageModal, setShowImageModal] = useState(false);
    const [images, setImages] = useState<any[]>([]);

    const onShowImageHandler = (images: any[]) => {
        setImages(images);
        setShowImageModal(!showImageModal);
    }

    return (
        <>
            <div className="w-full flex flex-col border-r-2 border-l-2 border-gray-100">
                <div className="w-full border-b-2 border-gray-200 grid grid-cols-11">
                    <div className='p-3 text-sm font-semibold tracking-wide text-left'>Display</div>
                    <div className='p-3 text-sm font-semibold tracking-wide text-left'>Fecha de creación</div>
                    <div className='p-3 text-sm font-semibold tracking-wide text-left col-span-2'>Fecha de inicio</div>
                    <div className='p-3 text-sm font-semibold tracking-wide text-left col-span-2'>Fecha de finalización</div>
                    <div className='p-3 text-sm font-semibold tracking-wide text-left'>Sucursal</div>
                    <div className='p-3 text-sm font-semibold tracking-wide text-left col-span-2'>Sección</div>
                    <div className='p-3 text-sm font-semibold tracking-wide text-left'>Subsección</div>
                    <div className='p-3 text-sm font-semibold tracking-wide text-left'>Imagen</div>
                </div>
            </div>

            {
                report.map((row: any, i: number) => (
                    i >= (30 * pagination - 30) && i < (30 * pagination) ?
                        <Accordion key={i} defaultExpanded={false}>
                            <AccordionSummary
                                expandIcon={<IonIcon icon={caretDown} className="text-[25px] text-black" />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ backgroundColor: '#F4F5F5' }}
                            >
                                <div className='w-full cursor:pointer text-sm text-gray-700 grid grid-cols-11'>
                                    <div className=''> {row.display} </div>
                                    <div> {row.created} </div>
                                    <div className='col-span-2'> {row.createdComplete} </div>
                                    <div className='col-span-2'> {row.finishedAt} </div>
                                    <div> {row.sucursal} </div>
                                    <div className='col-span-2'></div>
                                </div>
                            </AccordionSummary>

                            <AccordionDetails>
                                {
                                    row.data.map((item: any, index: number) => (
                                        <div key={index} className='w-full bg-white cursor:pointer [&>div]:p-2 text-sm text-gray-700 grid grid-cols-11'>
                                            <div> {item.name} </div>
                                            <div> {item.created} </div>
                                            <div className='col-span-2'> {item.createdComplete} </div>
                                            <div className='col-span-2'> {item.finishedAt} </div>
                                            <div> {item.nameSucursal} </div>
                                            <div className='col-span-2'> {item.section} </div>
                                            <div> {item.subSectiion} </div>
                                            {
                                                item.image !== '' &&
                                                <div>
                                                    <button
                                                        className='bg-gray-200 hover:bg-gray-300 font-semibold px-2 py-1 rounded-lg'
                                                        onClick={() => onShowImageHandler(item.image)}>Ver imagen
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    ))
                                }
                            </AccordionDetails>
                        </Accordion>
                        :
                        <tr key={i} style={{ display: "none" }}></tr>
                ))
            }
            <div className='mt-3 mb-3 flex justify-center'>
                <Pagination size="large" color="primary" count={Math.ceil(report.length / 30)} defaultPage={1} page={pagination} siblingCount={0} onChange={changePagination} />
            </div>
            <ImageVisualizerModal modalState={showImageModal} changeModalState={setShowImageModal} images={images} />
        </>
    );
}

export default ReportTableGondolaCold;