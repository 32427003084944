import BirthDay  from "Media/birthdays-premier.jpg";
import FavoriteIcon from '@mui/icons-material/Favorite';

const TabBirthdays = ( props: any )=> {
    const { data } = props;
    return (
        <>
            <div className="birthday-sections">
                <div>
                    <h1>¡MUCHAS FELICIDADES Y ÉXITOS EN TU DÍA!</h1>
                    <h3>DE TODA LA FAMILIA PREMIER</h3>
                    <h2>Cumpleañeros del día:</h2>
                    <div className="detials-container-birth">
                        {data.map((item: any, index: number) => {
                            return (
                                <div key={index} className="card-item ">
                                    <p className="name-item" >{item.name} {item.last_name}</p>
                                    <p className="message-item">{item.message}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {
                    data[0]?.image !== undefined ?
                        <img src={data[0].image} alt="Imagen del cumpleañero" />
                        :
                        <img src={BirthDay} alt="Imagen del cumpleañero" />
                }
            </div>
            <div className="footer-birthday">
                <p>PremierTec </p>
                <FavoriteIcon />
                <p> by PremTe</p>
            </div>
        </>
    )
}

export default TabBirthdays;