import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { Colors } from "Components/Data/Colors";

interface PropsInterface {
    lineData: any
    data: any
}
const TabTwo = (props: PropsInterface) => {
    const [diffdata, setDiffData] = useState<any>([])

    useEffect(() => {
        setDiffData(props.lineData)
    }, [props.lineData])

    const calculateColum = (item:number, type: "1" | "2"):number => {
        if ( type === "1" ) return item <= 100 ? item : 100
        else return item <= 100 ?  Math.round((100 - item)*100)/100 : 100
    }

    return (
        <>
            <div className="section-one ">
                <div className="graph-container">
                    <div className="card-container pie-chart">
                        <h3>Comparativa de Cumplimiento</h3>
                        <Chart
                            width={"100%"}
                            height={300}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={diffdata}
                            options={{
                                legend: "none",
                                chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
                                pieSliceText: "label",
                                pieHole: 0.5,
                                colors: Colors,
                            }}
                            rootProps={{ "data-testid": "1" }}
                            chartWrapperParams={{ view: { columns: [0, 2] } }}
                            chartPackages={["corechart", "controls"]}
                        />
                    </div>
                </div>
                <div className="card-root">
                    <div className="table-base">
                        {
                            props.data?.map((item: any, index: number) => {
                                return (
                                    <div key={index} className="row-two">
                                        <p>{item.type}</p>
                                        <div className="graphic-line-bar">
                                            <div 
                                                id="one" 
                                                style={{ 
                                                    height:"10px", 
                                                    backgroundColor:Colors[index], 
                                                    width: `${calculateColum(item.percentage, "1")}% `,
                                                    borderTopRightRadius: `${calculateColum(item.percentage, "1") === 100 ? "4px" : "0px"}`,
                                                    borderBottomRightRadius: `${calculateColum(item.percentage, "1") === 100 ? "4px" : "0px"}`,
                                                }}/>
                                            <div id="two" style={{ height:"10px", backgroundColor:Colors[index] + "77", width: `${calculateColum(item.percentage, "2")}%`}}></div>
                                        </div>
                                        <p>{item.percentage}%</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default TabTwo;