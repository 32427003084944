import {
    styled,
    TextField
} from "@mui/material";

export const CssTextField = styled(TextField)({
    color: "white",
    "& .MuiInputBase-root": {
        color: "#173456",
        fontWeight: "500",
        // "& input": {
        // //   textAlign: "center"
        //     fontWeight:"100"
        // }
    },
    '& label.Mui-focused': {
        color: '#173456',
    },
    "& .MuiText": {
        color: "#173456"
    },
    "& .MuiFormLabel-root": {
        color: "#173456"
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            color: '#173456',
            borderColor: '#173456',
        },
        '&:hover fieldset': {
            color: '#173456',
            borderColor: '#173456',
        },
        '&.Mui-focused fieldset': {
            color: '#173456',
            borderColor: '#173456',
        },
    },
});