
import { useEffect, useState } from "react";

interface PropsInterface {
    topSold: any
    month: string
    sold: string,
    year: number
}
const Menu = ( props: PropsInterface ) => {
    const [data, setData] = useState<any>([])

    useEffect(()=> {
        if ( props.topSold && props.topSold.length > 0 ) {
            let topSold = structuredClone(props.topSold)
            setData(() => topSold.sort((a: any, b: any) => b.sold - a.sold).slice(0, 3))
        }
    }, [props.topSold])

    return(
        <>
            <div className="cardItemsMenu">
                <div className="table-base" style={{ textAlign:"left"}}>
                    <h3 style={{ fontWeight:"bold", letterSpacing:"2px"}}>Top de ventas</h3>
                    {
                        data.map((item: any, index: number) => {
                            return (
                                <div key={index} className="row">
                                    <p>{item.vendor}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Menu