export const Colors = [
    '#ffb26c', 
    '#d7ea63', 
    '#7ca8fe', 
    '#1ed7b6', 
    '#496989',
    '#A25772',
    '#89CFF3',
    '#58A399',
    '#6499E9',
    '#635985',
    '#497174',
    '#51829B',
    '#436850',
    '#7FC7D9',
    '#3887BE',
    '#9BB8CD',
    '#F9F07A',
]