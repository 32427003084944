// Function to obtain the values of any filter in gondola and cold's report
export const getFilterValues = ( report: any, filter: any ) => {
    let arrayAux: string[] = [];

    // from gondola and cold's report obtained from the API, we retrieve the desired 
    // filter's value of each record
    report.map( (item: any) => (
        arrayAux.push( item[filter] )
    ))
    
    // we filter the arrayAux to get only one match of each record
    let filterValues = arrayAux.filter( (item: any, index: number) => {
        return arrayAux.indexOf( item ) === index;
    })

    return filterValues;
}

// Function to obtain the values of start dates and finish dates
export const getDateFilterValues = ( report: any, filter: any ) => {
    let arrayAux: any = [];
    let dateAux;
    let splitDate: any = [];
    
    // from gondola and cold's report obtained from the API, we retrieve the desired 
    // filter's value of each record
    report.forEach( (item: any) => {
        dateAux = item[filter];
        splitDate = dateAux.split(",");
        arrayAux.push( splitDate[0] )
    })

    // we filter the arrayAux to get only one match of each record
    let filterValues = arrayAux.filter( (item: any, index: number) => {
        return arrayAux.indexOf( item ) === index;
    })

    return filterValues;
}

// function to obtain the token of logged user from localStorage
export const getUserToken = () => {
    let user: any = localStorage.getItem("user");
    user = JSON.parse(user !== null ? user : "{}");
    return user.token;
}