import { useRef } from 'react';
import styled from "styled-components";
import useOnClickOutside from 'Hooks/useOnClickOutside';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ImageVisualizerModal = ({ modalState, changeModalState, images }: { modalState: any, changeModalState: any, images: any }) => {
    const imageContainerRef = useRef(null);

    const handleClickOutsideImage = () => {
        changeModalState(!modalState);
    }

    useOnClickOutside(imageContainerRef, handleClickOutsideImage);

    return (
        <>
            {
                modalState &&
                <>
                    <Overlay>
                        <TransformWrapper>
                            <TransformComponent >
                                <div ref={imageContainerRef} className='h-fit'>
                                    <img className="object-contain h-[95vh]" src={images} alt="Display" />

                                    <BotonCerrar onClick={() => changeModalState(!modalState)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </BotonCerrar>
                                </div>
                            </TransformComponent>
                        </TransformWrapper>
                    </Overlay>
                </>

            }
        </>
    );
}

export default ImageVisualizerModal;

const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const BotonCerrar = styled.button`
    position: fixed;
    top: 15px;
    right: 10px;
    width: 30px;
    height: 30px;
    border: none;
    background: #f2f2f2;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 5px;
    color: #1766DC;

    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover{
        background: #C3C3C3;
    }

    svg {
        width: 100%;
        height: 100%;
    }
`