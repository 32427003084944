import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Checkbox from '@mui/material/Checkbox';
import { SearchFilterInterface } from 'Interface';

const SearchFilter = ({
    label,
    persistentFilter,
    newValuePersistentFilter,
    labelCheckboxAll,
    filters,
    selectedFilters,
    isAllFiltersChecked,
    setIsAllFiltersChecked,
    setFilters, 
    setSelectedFilters, 
    setTotalSelectedFilters,
    handleSearchFilterChange,
    handleAllSearchFiltersChange
}:
    {
        label: string,
        persistentFilter: '' | 'display' | 'created' | 'createdComplete' |
        'finishedAt' | 'sucursal' | 'section' | 'subsection',
        newValuePersistentFilter: 'display' | 'created' | 'createdComplete' |
        'finishedAt' | 'sucursal' | 'section' | 'subsection',
        labelCheckboxAll: string,
        filters: SearchFilterInterface[],
        selectedFilters: string[],
        isAllFiltersChecked: boolean,
        setIsAllFiltersChecked: Dispatch<SetStateAction<boolean>>,
        setFilters: Dispatch<SetStateAction<SearchFilterInterface[]>>, 
        setSelectedFilters: Dispatch<SetStateAction<string[]>>, 
        setTotalSelectedFilters: Dispatch<SetStateAction<number>>,

        // función
        handleSearchFilterChange: (event: ChangeEvent<HTMLInputElement>, filters: SearchFilterInterface[], 
            filterName: 'display' | 'created' | 'createdComplete' |
            'finishedAt' | 'sucursal' | 'section' | 'subsection', setIsAllFiltersChecked: (value: SetStateAction<boolean>) => void, 
            setFilters: (value: SetStateAction<SearchFilterInterface[]>) => void, setSelectedFilters: (value: SetStateAction<string[]>) => void,
            setTotalSelectedFilters: (value: SetStateAction<number>) => void) => void,
            
        // función    
        handleAllSearchFiltersChange: (event: ChangeEvent<HTMLInputElement>, filters: SearchFilterInterface[], 
            filterName: 'display' | 'created' | 'createdComplete' |
            'finishedAt' | 'sucursal' | 'section' | 'subsection', setIsAllFiltersChecked: (value: SetStateAction<boolean>) => void, 
            setFilters: (value: SetStateAction<SearchFilterInterface[]>) => void, setSelectedFilters: (value: SetStateAction<string[]>) => void, 
            setTotalSelectedFilters: (value: SetStateAction<number>) => void) => void,
    }) => {

    return (
        <Accordion sx={{ boxShadow: 'none;' }}>
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon sx={{ color: '#000', width: '40px', height: '40px' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography sx={{ textAlign: 'start' }} component={'span'}>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography component={'span'}>
                    <FormGroup>
                        {
                            selectedFilters.length === 0 && (persistentFilter !== newValuePersistentFilter) ? <p>No hay datos para mostrar</p> :
                                <FormControlLabel control={<Checkbox id={`${newValuePersistentFilter}_all`} name={`${newValuePersistentFilter}_all`} value="all" checked={isAllFiltersChecked} onChange={e => handleAllSearchFiltersChange(e, filters, newValuePersistentFilter, setIsAllFiltersChecked, setFilters, setSelectedFilters, setTotalSelectedFilters)} />} label={labelCheckboxAll} />
                        }
                        {
                            filters.map((item: SearchFilterInterface) => (
                                item.visible &&
                                <FormControlLabel key={item.value} control={<Checkbox id={item.value} name={item.value} value={item.value} checked={item.checked} onChange={e => handleSearchFilterChange(e, filters, newValuePersistentFilter, setIsAllFiltersChecked, setFilters, setSelectedFilters, setTotalSelectedFilters)} />} label={item.value} />
                            ))
                        }
                    </FormGroup>
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
}

export default SearchFilter;