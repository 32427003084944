import { useState, useEffect } from 'react';
import { CircularProgress } from "@mui/material";
import { getGondolaColdReport } from 'Services/GondolaColdService';
import NavbarDashboards from 'Components/NavbarDashboards/NavbarDashboards';
import SearchFiltersGondolaCold from 'Components/DashboardGondolaCold/SearchFiltersGondolaCold';
import { getFilterValues, getDateFilterValues, getUserToken } from 'Utils/UtilsDashboards';
import MenuOptionsDashboards from 'Components/MenuOptionsDashboards/MenuOptionsDashboards';
import ReportTableGondolaCold from 'Components/DashboardGondolaCold/ReportTableGondolaCold';
import ReportCardGondolaCold from 'Components/DashboardGondolaCold/ReportCardGondolaCold';
import { SearchFilterInterface } from 'Interface';

const DashboardGondolaCold = () => {
    // states that help us to show the report
    const [report, setReport] = useState<any>([]);
    const [filteredReport, setFilteredReport] = useState<any>([]);
    const [reportDates, setReportDates] = useState("");

    // estados que nos ayudarán a preservar el checked de los checkbox cuando se elimine o se agregue
    // algún otro checkbox
    const [displays, setDisplays] = useState<SearchFilterInterface[]>([]);
    const [dates, setDates] = useState<SearchFilterInterface[]>([]);
    const [startDates, setStartDates] = useState<SearchFilterInterface[]>([]);
    const [finishDates, setFinishDates] = useState<SearchFilterInterface[]>([]);
    const [sucursals, setSucursals] = useState<SearchFilterInterface[]>([]);
    const [sections, setSections] = useState<SearchFilterInterface[]>([]);
    const [subsections, setSubsections] = useState<SearchFilterInterface[]>([]);

    // states that help us to filter the report
    const [filteredDisplays, setFilteredDisplays] = useState<string[]>([]);
    const [filteredDates, setFilteredDates] = useState<string[]>([]);
    const [filteredStartDates, setFilteredStartDates] = useState<string[]>([]);
    const [filteredFinishDates, setFilteredFinishDates] = useState<string[]>([]);
    const [filteredSucursals, setFilteredSucursals] = useState<string[]>([]);
    const [filteredSections, setFilteredSections] = useState<string[]>([]);
    const [filteredSubsections, setFilteredSubsections] = useState<string[]>([]);

    const [persistentFilter, setPersistentFilter] = useState<'display' | 'created' | 'createdComplete' |
        'finishedAt' | 'sucursal' | 'section' | 'subsection' | "">("");

    // loader
    const [isLoading, setIsLoading] = useState(true);

    // state that help us to know if we have to show the hamburguer menu option
    const [clicked, setClicked] = useState(false);

    const handlerClick = () => {
        setClicked(!clicked);
    }

    // function to obtain the report from the API and filter's options
    const getReport = async () => {
        // obtains user's token
        const userToken = getUserToken();

        // obtains gondola and cold's report
        const report = await getGondolaColdReport(userToken);

        setReport(report);

        // obtains display's name in the report
        const visibleDisplaysArray = getFilterValues(report, "name");

        let displaysArray: SearchFilterInterface[] = [];

        visibleDisplaysArray.forEach((displayValue: string) => {
            let display: SearchFilterInterface = {
                value: displayValue,
                visible: true,
                checked: true
            }

            displaysArray.push(display);
        })

        setDisplays(displaysArray);
        setFilteredDisplays(visibleDisplaysArray);

        // obtains dates in the report
        const visibleDatesArray = getFilterValues(report, "created");

        let datesArray: SearchFilterInterface[] = [];

        visibleDatesArray.forEach((dateValue: string) => {
            let date: SearchFilterInterface = {
                value: dateValue,
                visible: true,
                checked: true
            }

            datesArray.push(date);
        })

        setDates(datesArray);
        setFilteredDates(visibleDatesArray);

        // obtains start dates in the report
        const visibleStartDatesArray = getDateFilterValues(report, "createdComplete");

        let startDatesArray: SearchFilterInterface[] = [];

        visibleStartDatesArray.forEach((dateValue: string) => {
            let date: SearchFilterInterface = {
                value: dateValue,
                visible: true,
                checked: true
            }

            startDatesArray.push(date);
        })

        setStartDates(startDatesArray);
        setFilteredStartDates(visibleStartDatesArray);


        // obtains finish dates in the report
        const visibleFinishDatesArray = getDateFilterValues(report, "finishedAt");

        let finishDatesArray: SearchFilterInterface[] = [];

        visibleFinishDatesArray.forEach((dateValue: string) => {
            let date: SearchFilterInterface = {
                value: dateValue,
                visible: true,
                checked: true
            }

            finishDatesArray.push(date);
        })

        setFinishDates(finishDatesArray);
        setFilteredFinishDates(visibleFinishDatesArray);

        // obtains sucursals in the report
        const visibleSucursalsArray = getFilterValues(report, "nameSucursal");

        let sucursalsArray: SearchFilterInterface[] = [];

        visibleSucursalsArray.forEach((sucursalValue: string) => {
            let sucursal: SearchFilterInterface = {
                value: sucursalValue,
                visible: true,
                checked: true
            }

            sucursalsArray.push(sucursal);
        })

        setSucursals(sucursalsArray);
        setFilteredSucursals(visibleSucursalsArray);

        // obtains sections in the report
        const visibleSectionsArray = getFilterValues(report, "section");

        let sectionsArray: SearchFilterInterface[] = [];

        visibleSectionsArray.forEach((sectionValue: string) => {
            let section: SearchFilterInterface = {
                value: sectionValue,
                visible: true,
                checked: true
            }

            sectionsArray.push(section);
        })

        setSections(sectionsArray);
        setFilteredSections(visibleSectionsArray);

        // obtains subsections in the report
        const visibleSubsectionsArray = getFilterValues(report, "subSectiion");

        let subsectionsArray: SearchFilterInterface[] = [];

        visibleSubsectionsArray.forEach((subsectionValue: string) => {
            let subsection: SearchFilterInterface = {
                value: subsectionValue,
                visible: true,
                checked: true
            }

            subsectionsArray.push(subsection);
        })

        setSubsections(subsectionsArray);
        setFilteredSubsections(visibleSubsectionsArray);

        const reportDates = "Del " + datesArray[0].value + " al " + datesArray[datesArray.length - 1].value;
        setReportDates(reportDates);

        let newReport: any[] = [];

        sucursalsArray.forEach((sucursal: SearchFilterInterface) => {
            const data = report.filter((row: any) => row.nameSucursal === sucursal.value);

            const newData = {
                display: data[0].name,
                created: data[0].created,
                createdComplete: data[0].createdComplete,
                finishedAt: data[0].finishedAt,
                sucursal: sucursal.value,
                data
            }

            newReport.push(newData);
        });

        setFilteredReport(newReport);

        // set the value of false to the variable that controls the loading screen 
        setIsLoading(false);
    }

    // states that help us to know if we have to do any filtering
    const [totalFilteredDisplays, setTotalFilteredDisplays] = useState(-1);
    const [totalFilteredDates, setTotalFilteredDates] = useState(-1);
    const [totalFilteredStartDates, setTotalFilteredStartDates] = useState(-1);
    const [totalFilteredFinishDates, setTotalFilteredFinishDates] = useState(-1);
    const [totalFilteredSucursals, setTotalFilteredSucursals] = useState(-1);
    const [totalFilteredSections, setTotalFilteredSections] = useState(-1);
    const [totalFilteredSubsections, setTotalFilteredSubsections] = useState(-1);

    // functions to filter the report
    useEffect(() => {
        const arrayAux = report.filter((row: any) =>
            filteredDisplays.includes(row.name)
        );

        // obtains dates in the report
        const visibleDatesArray = getFilterValues(arrayAux, "created");
        let datesArrayAux: string[] = [];

        const newDatesArray = dates.map(date => {
            if (visibleDatesArray.includes(date.value)) {
                if (date.checked) {
                    datesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "created") {
            setDates(newDatesArray);
            setFilteredDates(visibleDatesArray);
        }

        // obtains start dates in the report
        const visibleStartDatesArray = getDateFilterValues(arrayAux, "createdComplete");
        let startDatesArrayAux: string[] = [];

        const newStartDatesArray = startDates.map(date => {
            if (visibleStartDatesArray.includes(date.value)) {
                if (date.checked) {
                    startDatesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "createdComplete") {
            setStartDates(newStartDatesArray);
            setFilteredStartDates(visibleStartDatesArray);
        }

        // obtains finish dates in the report
        const visibleFinishDatesArray = getDateFilterValues(arrayAux, "finishedAt");
        let finishDatesArrayAux: string[] = [];

        const newFinishDatesArray = finishDates.map(date => {
            if (visibleFinishDatesArray.includes(date.value)) {
                if (date.checked) {
                    finishDatesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "finishedAt") {
            setFinishDates(newFinishDatesArray);
            setFilteredFinishDates(visibleFinishDatesArray);
        }

        // obtains sucursals in the report
        const visibleSucursalsArray = getFilterValues(arrayAux, "nameSucursal");
        let sucursalsArrayAux: string[] = [];

        const newSucursalsArray = sucursals.map(sucursal => {
            if (visibleSucursalsArray.includes(sucursal.value)) {
                if (sucursal.checked) {
                    sucursalsArrayAux.push(sucursal.value);
                }
                return { ...sucursal, visible: true };
            } else {
                return { ...sucursal, visible: false };
            }
        });

        if (persistentFilter !== "sucursal") {
            setSucursals(newSucursalsArray);
            setFilteredSucursals(visibleSucursalsArray);
        }

        // obtains sections in the report
        const visibleSectionsArray = getFilterValues(arrayAux, "section");
        let sectionsArrayAux: string[] = [];

        const newSectionsArray = sections.map(section => {
            if (visibleSectionsArray.includes(section.value)) {
                if (section.checked) {
                    sectionsArrayAux.push(section.value);
                }
                return { ...section, visible: true };
            } else {
                return { ...section, visible: false };
            }
        });

        if (persistentFilter !== "section") {
            setSections(newSectionsArray);
            setFilteredSections(visibleSectionsArray);
        }

        // obtains subsections in the report
        const visibleSubsectionsArray = getFilterValues(arrayAux, "subSectiion");
        let subsectionsArrayAux: string[] = [];

        const newSubsectionsArray = subsections.map(subsection => {
            if (visibleSubsectionsArray.includes(subsection.value)) {
                if (subsection.checked) {
                    subsectionsArrayAux.push(subsection.value);
                }
                return { ...subsection, visible: true };
            } else {
                return { ...subsection, visible: false };
            }
        });

        if (persistentFilter !== "subsection") {
            setSubsections(newSubsectionsArray);
            setFilteredSubsections(visibleSubsectionsArray);
        }

        // filtramos nuevamente el reporte
        let newReport = arrayAux.filter((row: any) => {
            let [startDate] = row.createdComplete.split(",");
            let [finishDate] = row.finishedAt.split(",");

            return (
                datesArrayAux.includes(row.created) &&
                startDatesArrayAux.includes(startDate) &&
                finishDatesArrayAux.includes(finishDate) &&
                sucursalsArrayAux.includes(row.nameSucursal) &&
                sectionsArrayAux.includes(row.section) &&
                subsectionsArrayAux.includes(row.subSectiion)
            )
        });

        let finalReport: any[] = [];

        newSucursalsArray.forEach((sucursal: SearchFilterInterface) => {
            if (sucursal.visible) {

                const data = newReport.filter((row: any) => row.nameSucursal === sucursal.value);

                const newData = {
                    display: data[0].name,
                    created: data[0].created,
                    createdComplete: data[0].createdComplete,
                    finishedAt: data[0].finishedAt,
                    sucursal: sucursal.value,
                    data
                }

                finalReport.push(newData);
            }
        });

        setFilteredReport(finalReport);
    }, [totalFilteredDisplays])

    useEffect(() => {
        const arrayAux = report.filter((row: any) =>
            filteredDates.includes(row.created)
        );

        // obtains displays in the report
        const visibleDisplaysArray = getFilterValues(arrayAux, "name");
        let displaysArrayAux: string[] = [];

        const newDisplaysArray = displays.map(display => {
            if (visibleDisplaysArray.includes(display.value)) {
                if (display.checked) {
                    displaysArrayAux.push(display.value);
                }
                return { ...display, visible: true };
            } else {
                return { ...display, visible: false };
            }
        });

        if (persistentFilter !== "display") {
            setDisplays(newDisplaysArray);
            setFilteredDisplays(visibleDisplaysArray);
        }

        // obtains start dates in the report
        const visibleStartDatesArray = getDateFilterValues(arrayAux, "createdComplete");
        let startDatesArrayAux: string[] = [];

        const newStartDatesArray = startDates.map(date => {
            if (visibleStartDatesArray.includes(date.value)) {
                if (date.checked) {
                    startDatesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "createdComplete") {
            setStartDates(newStartDatesArray);
            setFilteredStartDates(visibleStartDatesArray);
        }

        // obtains finish dates in the report
        const visibleFinishDatesArray = getDateFilterValues(arrayAux, "finishedAt");
        let finishDatesArrayAux: string[] = [];

        const newFinishDatesArray = finishDates.map(date => {
            if (visibleFinishDatesArray.includes(date.value)) {
                if (date.checked) {
                    finishDatesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "finishedAt") {
            setFinishDates(newFinishDatesArray);
            setFilteredFinishDates(visibleFinishDatesArray);
        }

        // obtains sucursals in the report
        const visibleSucursalsArray = getFilterValues(arrayAux, "nameSucursal");
        let sucursalsArrayAux: string[] = [];

        const newSucursalsArray = sucursals.map(sucursal => {
            if (visibleSucursalsArray.includes(sucursal.value)) {
                if (sucursal.checked) {
                    sucursalsArrayAux.push(sucursal.value);
                }
                return { ...sucursal, visible: true };
            } else {
                return { ...sucursal, visible: false };
            }
        });

        if (persistentFilter !== "sucursal") {
            setSucursals(newSucursalsArray);
            setFilteredSucursals(visibleSucursalsArray);
        }

        // obtains sections in the report
        const visibleSectionsArray = getFilterValues(arrayAux, "section");
        let sectionsArrayAux: string[] = [];

        const newSectionsArray = sections.map(section => {
            if (visibleSectionsArray.includes(section.value)) {
                if (section.checked) {
                    sectionsArrayAux.push(section.value);
                }
                return { ...section, visible: true };
            } else {
                return { ...section, visible: false };
            }
        });

        if (persistentFilter !== "section") {
            setSections(newSectionsArray);
            setFilteredSections(visibleSectionsArray);
        }

        // obtains subsections in the report
        const visibleSubsectionsArray = getFilterValues(arrayAux, "subSectiion");
        let subsectionsArrayAux: string[] = [];

        const newSubsectionsArray = subsections.map(subsection => {
            if (visibleSubsectionsArray.includes(subsection.value)) {
                if (subsection.checked) {
                    subsectionsArrayAux.push(subsection.value);
                }
                return { ...subsection, visible: true };
            } else {
                return { ...subsection, visible: false };
            }
        });

        if (persistentFilter !== "subsection") {
            setSubsections(newSubsectionsArray);
            setFilteredSubsections(visibleSubsectionsArray);
        }

        // filtramos nuevamente el reporte
        let newReport = arrayAux.filter((row: any) => {
            let [startDate] = row.createdComplete.split(",");
            let [finishDate] = row.finishedAt.split(",");

            return (
                displaysArrayAux.includes(row.name) &&
                startDatesArrayAux.includes(startDate) &&
                finishDatesArrayAux.includes(finishDate) &&
                sucursalsArrayAux.includes(row.nameSucursal) &&
                sectionsArrayAux.includes(row.section) &&
                subsectionsArrayAux.includes(row.subSectiion)
            )
        });

        let finalReport: any[] = [];

        newSucursalsArray.forEach((sucursal: SearchFilterInterface) => {
            if (sucursal.visible) {

                const data = newReport.filter((row: any) => row.nameSucursal === sucursal.value);

                const newData = {
                    display: data[0].name,
                    created: data[0].created,
                    createdComplete: data[0].createdComplete,
                    finishedAt: data[0].finishedAt,
                    sucursal: sucursal.value,
                    data
                }

                finalReport.push(newData);
            }
        });

        setFilteredReport(finalReport);
    }, [totalFilteredDates])

    useEffect(() => {
        const arrayAux = report.filter((row: any) => {
            const [startDate] = row.createdComplete.split(",");

            return filteredStartDates.includes(startDate);
        });

        // obtains displays in the report
        const visibleDisplaysArray = getFilterValues(arrayAux, "name");
        let displaysArrayAux: string[] = [];

        const newDisplaysArray = displays.map(display => {
            if (visibleDisplaysArray.includes(display.value)) {
                if (display.checked) {
                    displaysArrayAux.push(display.value);
                }
                return { ...display, visible: true };
            } else {
                return { ...display, visible: false };
            }
        });

        if (persistentFilter !== "display") {
            setDisplays(newDisplaysArray);
            setFilteredDisplays(visibleDisplaysArray);
        }

        // obtains dates in the report
        const visibleDatesArray = getFilterValues(arrayAux, "created");
        let datesArrayAux: string[] = [];

        const newDatesArray = dates.map(date => {
            if (visibleDatesArray.includes(date.value)) {
                if (date.checked) {
                    datesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "created") {
            setDates(newDatesArray);
            setFilteredDates(visibleDatesArray);
        }

        // obtains finish dates in the report
        const visibleFinishDatesArray = getDateFilterValues(arrayAux, "finishedAt");
        let finishDatesArrayAux: string[] = [];

        const newFinishDatesArray = finishDates.map(date => {
            if (visibleFinishDatesArray.includes(date.value)) {
                if (date.checked) {
                    finishDatesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "finishedAt") {
            setFinishDates(newFinishDatesArray);
            setFilteredFinishDates(visibleFinishDatesArray);
        }

        // obtains sucursals in the report
        const visibleSucursalsArray = getFilterValues(arrayAux, "nameSucursal");
        let sucursalsArrayAux: string[] = [];

        const newSucursalsArray = sucursals.map(sucursal => {
            if (visibleSucursalsArray.includes(sucursal.value)) {
                if (sucursal.checked) {
                    sucursalsArrayAux.push(sucursal.value);
                }
                return { ...sucursal, visible: true };
            } else {
                return { ...sucursal, visible: false };
            }
        });

        if (persistentFilter !== "sucursal") {
            setSucursals(newSucursalsArray);
            setFilteredSucursals(visibleSucursalsArray);
        }

        // obtains sections in the report
        const visibleSectionsArray = getFilterValues(arrayAux, "section");
        let sectionsArrayAux: string[] = [];

        const newSectionsArray = sections.map(section => {
            if (visibleSectionsArray.includes(section.value)) {
                if (section.checked) {
                    sectionsArrayAux.push(section.value);
                }
                return { ...section, visible: true };
            } else {
                return { ...section, visible: false };
            }
        });

        if (persistentFilter !== "section") {
            setSections(newSectionsArray);
            setFilteredSections(visibleSectionsArray);
        }

        // obtains subsections in the report
        const visibleSubsectionsArray = getFilterValues(arrayAux, "subSectiion");
        let subsectionsArrayAux: string[] = [];

        const newSubsectionsArray = subsections.map(subsection => {
            if (visibleSubsectionsArray.includes(subsection.value)) {
                if (subsection.checked) {
                    subsectionsArrayAux.push(subsection.value);
                }
                return { ...subsection, visible: true };
            } else {
                return { ...subsection, visible: false };
            }
        });

        if (persistentFilter !== "subsection") {
            setSubsections(newSubsectionsArray);
            setFilteredSubsections(visibleSubsectionsArray);
        }

        // filtramos nuevamente el reporte
        let newReport = arrayAux.filter((row: any) => {
            let [finishDate] = row.finishedAt.split(",");

            return (
                displaysArrayAux.includes(row.name) &&
                datesArrayAux.includes(row.created) &&
                finishDatesArrayAux.includes(finishDate) &&
                sucursalsArrayAux.includes(row.nameSucursal) &&
                sectionsArrayAux.includes(row.section) &&
                subsectionsArrayAux.includes(row.subSectiion)
            )
        });

        let finalReport: any[] = [];

        newSucursalsArray.forEach((sucursal: SearchFilterInterface) => {
            if (sucursal.visible) {

                const data = newReport.filter((row: any) => row.nameSucursal === sucursal.value);

                const newData = {
                    display: data[0].name,
                    created: data[0].created,
                    createdComplete: data[0].createdComplete,
                    finishedAt: data[0].finishedAt,
                    sucursal: sucursal.value,
                    data
                }

                finalReport.push(newData);
            }
        });

        setFilteredReport(finalReport);
    }, [totalFilteredStartDates])

    useEffect(() => {
        const arrayAux = report.filter((row: any) => {
            const [finishDate] = row.finishedAt.split(",");

            return filteredFinishDates.includes(finishDate);
        });

        // obtains displays in the report
        const visibleDisplaysArray = getFilterValues(arrayAux, "name");
        let displaysArrayAux: string[] = [];

        const newDisplaysArray = displays.map(display => {
            if (visibleDisplaysArray.includes(display.value)) {
                if (display.checked) {
                    displaysArrayAux.push(display.value);
                }
                return { ...display, visible: true };
            } else {
                return { ...display, visible: false };
            }
        });

        if (persistentFilter !== "display") {
            setDisplays(newDisplaysArray);
            setFilteredDisplays(visibleDisplaysArray);
        }

        // obtains dates in the report
        const visibleDatesArray = getFilterValues(arrayAux, "created");
        let datesArrayAux: string[] = [];

        const newDatesArray = dates.map(date => {
            if (visibleDatesArray.includes(date.value)) {
                if (date.checked) {
                    datesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "created") {
            setDates(newDatesArray);
            setFilteredDates(visibleDatesArray);
        }

        // obtains start dates in the report
        const visibleStartDatesArray = getDateFilterValues(arrayAux, "createdComplete");
        let startDatesArrayAux: string[] = [];

        const newStartDatesArray = startDates.map(date => {
            if (visibleStartDatesArray.includes(date.value)) {
                if (date.checked) {
                    startDatesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "createdComplete") {
            setStartDates(newStartDatesArray);
            setFilteredStartDates(visibleStartDatesArray);
        }

        // obtains sucursals in the report
        const visibleSucursalsArray = getFilterValues(arrayAux, "nameSucursal");
        let sucursalsArrayAux: string[] = [];

        const newSucursalsArray = sucursals.map(sucursal => {
            if (visibleSucursalsArray.includes(sucursal.value)) {
                if (sucursal.checked) {
                    sucursalsArrayAux.push(sucursal.value);
                }
                return { ...sucursal, visible: true };
            } else {
                return { ...sucursal, visible: false };
            }
        });

        if (persistentFilter !== "sucursal") {
            setSucursals(newSucursalsArray);
            setFilteredSucursals(visibleSucursalsArray);
        }

        // obtains sections in the report
        const visibleSectionsArray = getFilterValues(arrayAux, "section");
        let sectionsArrayAux: string[] = [];

        const newSectionsArray = sections.map(section => {
            if (visibleSectionsArray.includes(section.value)) {
                if (section.checked) {
                    sectionsArrayAux.push(section.value);
                }
                return { ...section, visible: true };
            } else {
                return { ...section, visible: false };
            }
        });

        if (persistentFilter !== "section") {
            setSections(newSectionsArray);
            setFilteredSections(visibleSectionsArray);
        }

        // obtains subsections in the report
        const visibleSubsectionsArray = getFilterValues(arrayAux, "subSectiion");
        let subsectionsArrayAux: string[] = [];

        const newSubsectionsArray = subsections.map(subsection => {
            if (visibleSubsectionsArray.includes(subsection.value)) {
                if (subsection.checked) {
                    subsectionsArrayAux.push(subsection.value);
                }
                return { ...subsection, visible: true };
            } else {
                return { ...subsection, visible: false };
            }
        });

        if (persistentFilter !== "subsection") {
            setSubsections(newSubsectionsArray);
            setFilteredSubsections(visibleSubsectionsArray);
        }

        // filtramos nuevamente el reporte
        let newReport = arrayAux.filter((row: any) => {
            let [startDate] = row.createdComplete.split(",");

            return (
                displaysArrayAux.includes(row.name) &&
                datesArrayAux.includes(row.created) &&
                startDatesArrayAux.includes(startDate) &&
                sucursalsArrayAux.includes(row.nameSucursal) &&
                sectionsArrayAux.includes(row.section) &&
                subsectionsArrayAux.includes(row.subSectiion)
            )
        });

        let finalReport: any[] = [];

        newSucursalsArray.forEach((sucursal: SearchFilterInterface) => {
            if (sucursal.visible) {

                const data = newReport.filter((row: any) => row.nameSucursal === sucursal.value);

                const newData = {
                    display: data[0].name,
                    created: data[0].created,
                    createdComplete: data[0].createdComplete,
                    finishedAt: data[0].finishedAt,
                    sucursal: sucursal.value,
                    data
                }

                finalReport.push(newData);
            }
        });

        setFilteredReport(finalReport);
    }, [totalFilteredFinishDates])

    useEffect(() => {
        const arrayAux = report.filter((row: any) =>
            filteredSucursals.includes(row.nameSucursal)
        );

        // obtains displays in the report
        const visibleDisplaysArray = getFilterValues(arrayAux, "name");
        let displaysArrayAux: string[] = [];

        const newDisplaysArray = displays.map(display => {
            if (visibleDisplaysArray.includes(display.value)) {
                if (display.checked) {
                    displaysArrayAux.push(display.value);
                }
                return { ...display, visible: true };
            } else {
                return { ...display, visible: false };
            }
        });

        if (persistentFilter !== "display") {
            setDisplays(newDisplaysArray);
            setFilteredDisplays(visibleDisplaysArray);
        }

        // obtains dates in the report
        const visibleDatesArray = getFilterValues(arrayAux, "created");
        let datesArrayAux: string[] = [];

        const newDatesArray = dates.map(date => {
            if (visibleDatesArray.includes(date.value)) {
                if (date.checked) {
                    datesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "created") {
            setDates(newDatesArray);
            setFilteredDates(visibleDatesArray);
        }

        // obtains start dates in the report
        const visibleStartDatesArray = getDateFilterValues(arrayAux, "createdComplete");
        let startDatesArrayAux: string[] = [];

        const newStartDatesArray = startDates.map(date => {
            if (visibleStartDatesArray.includes(date.value)) {
                if (date.checked) {
                    startDatesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "createdComplete") {
            setStartDates(newStartDatesArray);
            setFilteredStartDates(visibleStartDatesArray);
        }

        // obtains finish dates in the report
        const visibleFinishDatesArray = getDateFilterValues(arrayAux, "finishedAt");
        let finishDatesArrayAux: string[] = [];

        const newFinishDatesArray = finishDates.map(date => {
            if (visibleFinishDatesArray.includes(date.value)) {
                if (date.checked) {
                    finishDatesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "finishedAt") {
            setFinishDates(newFinishDatesArray);
            setFilteredFinishDates(visibleFinishDatesArray);
        }

        // obtains sections in the report
        const visibleSectionsArray = getFilterValues(arrayAux, "section");
        let sectionsArrayAux: string[] = [];

        const newSectionsArray = sections.map(section => {
            if (visibleSectionsArray.includes(section.value)) {
                if (section.checked) {
                    sectionsArrayAux.push(section.value);
                }
                return { ...section, visible: true };
            } else {
                return { ...section, visible: false };
            }
        });

        if (persistentFilter !== "section") {
            setSections(newSectionsArray);
            setFilteredSections(visibleSectionsArray);
        }

        // obtains subsections in the report
        const visibleSubsectionsArray = getFilterValues(arrayAux, "subSectiion");
        let subsectionsArrayAux: string[] = [];

        const newSubsectionsArray = subsections.map(subsection => {
            if (visibleSubsectionsArray.includes(subsection.value)) {
                if (subsection.checked) {
                    subsectionsArrayAux.push(subsection.value);
                }
                return { ...subsection, visible: true };
            } else {
                return { ...subsection, visible: false };
            }
        });

        if (persistentFilter !== "subsection") {
            setSubsections(newSubsectionsArray);
            setFilteredSubsections(visibleSubsectionsArray);
        }

        // filtramos nuevamente el reporte
        let newReport = arrayAux.filter((row: any) => {
            let [startDate] = row.createdComplete.split(",");
            let [finishDate] = row.finishedAt.split(",");

            return (
                displaysArrayAux.includes(row.name) &&
                datesArrayAux.includes(row.created) &&
                startDatesArrayAux.includes(startDate) &&
                finishDatesArrayAux.includes(finishDate) &&
                sectionsArrayAux.includes(row.section) &&
                subsectionsArrayAux.includes(row.subSectiion)
            )
        });

        let finalReport: any[] = [];

        filteredSucursals.forEach((sucursal: string) => {

            const data = newReport.filter((row: any) => row.nameSucursal === sucursal);

            const newData = {
                display: data[0].name,
                created: data[0].created,
                createdComplete: data[0].createdComplete,
                finishedAt: data[0].finishedAt,
                sucursal: sucursal,
                data
            }

            finalReport.push(newData);
        });

        setFilteredReport(finalReport);
    }, [totalFilteredSucursals])

    useEffect(() => {
        const arrayAux = report.filter((row: any) =>
            filteredSections.includes(row.section)
        );

        // obtains displays in the report
        const visibleDisplaysArray = getFilterValues(arrayAux, "name");
        let displaysArrayAux: string[] = [];

        const newDisplaysArray = displays.map(display => {
            if (visibleDisplaysArray.includes(display.value)) {
                if (display.checked) {
                    displaysArrayAux.push(display.value);
                }
                return { ...display, visible: true };
            } else {
                return { ...display, visible: false };
            }
        });

        if (persistentFilter !== "display") {
            setDisplays(newDisplaysArray);
            setFilteredDisplays(visibleDisplaysArray);
        }

        // obtains dates in the report
        const visibleDatesArray = getFilterValues(arrayAux, "created");
        let datesArrayAux: string[] = [];

        const newDatesArray = dates.map(date => {
            if (visibleDatesArray.includes(date.value)) {
                if (date.checked) {
                    datesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "created") {
            setDates(newDatesArray);
            setFilteredDates(visibleDatesArray);
        }

        // obtains start dates in the report
        const visibleStartDatesArray = getDateFilterValues(arrayAux, "createdComplete");
        let startDatesArrayAux: string[] = [];

        const newStartDatesArray = startDates.map(date => {
            if (visibleStartDatesArray.includes(date.value)) {
                if (date.checked) {
                    startDatesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "createdComplete") {
            setStartDates(newStartDatesArray);
            setFilteredStartDates(visibleStartDatesArray);
        }

        // obtains finish dates in the report
        const visibleFinishDatesArray = getDateFilterValues(arrayAux, "finishedAt");
        let finishDatesArrayAux: string[] = [];

        const newFinishDatesArray = finishDates.map(date => {
            if (visibleFinishDatesArray.includes(date.value)) {
                if (date.checked) {
                    finishDatesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "finishedAt") {
            setFinishDates(newFinishDatesArray);
            setFilteredFinishDates(visibleFinishDatesArray);
        }

        // obtains sucursals in the report
        const visibleSucursalsArray = getFilterValues(arrayAux, "nameSucursal");
        let sucursalsArrayAux: string[] = [];

        const newSucursalsArray = sucursals.map(sucursal => {
            if (visibleSucursalsArray.includes(sucursal.value)) {
                if (sucursal.checked) {
                    sucursalsArrayAux.push(sucursal.value);
                }
                return { ...sucursal, visible: true };
            } else {
                return { ...sucursal, visible: false };
            }
        });

        if (persistentFilter !== "sucursal") {
            setSucursals(newSucursalsArray);
            setFilteredSucursals(visibleSucursalsArray);
        }

        // obtains subsections in the report
        const visibleSubsectionsArray = getFilterValues(arrayAux, "subSectiion");
        let subsectionsArrayAux: string[] = [];

        const newSubsectionsArray = subsections.map(subsection => {
            if (visibleSubsectionsArray.includes(subsection.value)) {
                if (subsection.checked) {
                    subsectionsArrayAux.push(subsection.value);
                }
                return { ...subsection, visible: true };
            } else {
                return { ...subsection, visible: false };
            }
        });

        if (persistentFilter !== "subsection") {
            setSubsections(newSubsectionsArray);
            setFilteredSubsections(visibleSubsectionsArray);
        }

        // filtramos nuevamente el reporte
        let newReport = arrayAux.filter((row: any) => {
            let [startDate] = row.createdComplete.split(",");
            let [finishDate] = row.finishedAt.split(",");

            return (
                displaysArrayAux.includes(row.name) &&
                datesArrayAux.includes(row.created) &&
                startDatesArrayAux.includes(startDate) &&
                finishDatesArrayAux.includes(finishDate) &&
                sucursalsArrayAux.includes(row.nameSucursal) &&
                subsectionsArrayAux.includes(row.subSectiion)
            )
        });

        let finalReport: any[] = [];

        newSucursalsArray.forEach((sucursal: SearchFilterInterface) => {
            if (sucursal.visible) {

                const data = newReport.filter((row: any) => row.nameSucursal === sucursal.value);

                const newData = {
                    display: data[0].name,
                    created: data[0].created,
                    createdComplete: data[0].createdComplete,
                    finishedAt: data[0].finishedAt,
                    sucursal: sucursal.value,
                    data
                }

                finalReport.push(newData);
            }
        });

        setFilteredReport(finalReport);
    }, [totalFilteredSections])

    useEffect(() => {
        const arrayAux = report.filter((row: any) =>
            filteredSubsections.includes(row.subSectiion)
        );

        // obtains displays in the report
        const visibleDisplaysArray = getFilterValues(arrayAux, "name");
        let displaysArrayAux: string[] = [];

        const newDisplaysArray = displays.map(display => {
            if (visibleDisplaysArray.includes(display.value)) {
                if (display.checked) {
                    displaysArrayAux.push(display.value);
                }
                return { ...display, visible: true };
            } else {
                return { ...display, visible: false };
            }
        });

        if (persistentFilter !== "display") {
            setDisplays(newDisplaysArray);
            setFilteredDisplays(visibleDisplaysArray);
        }

        // obtains dates in the report
        const visibleDatesArray = getFilterValues(arrayAux, "created");
        let datesArrayAux: string[] = [];

        const newDatesArray = dates.map(date => {
            if (visibleDatesArray.includes(date.value)) {
                if (date.checked) {
                    datesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "created") {
            setDates(newDatesArray);
            setFilteredDates(visibleDatesArray);
        }

        // obtains start dates in the report
        const visibleStartDatesArray = getDateFilterValues(arrayAux, "createdComplete");
        let startDatesArrayAux: string[] = [];

        const newStartDatesArray = startDates.map(date => {
            if (visibleStartDatesArray.includes(date.value)) {
                if (date.checked) {
                    startDatesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "createdComplete") {
            setStartDates(newStartDatesArray);
            setFilteredStartDates(visibleStartDatesArray);
        }

        // obtains finish dates in the report
        const visibleFinishDatesArray = getDateFilterValues(arrayAux, "finishedAt");
        let finishDatesArrayAux: string[] = [];

        const newFinishDatesArray = finishDates.map(date => {
            if (visibleFinishDatesArray.includes(date.value)) {
                if (date.checked) {
                    finishDatesArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "finishedAt") {
            setFinishDates(newFinishDatesArray);
            setFilteredFinishDates(visibleFinishDatesArray);
        }

        // obtains sucursals in the report
        const visibleSucursalsArray = getFilterValues(arrayAux, "nameSucursal");
        let sucursalsArrayAux: string[] = [];

        const newSucursalsArray = sucursals.map(sucursal => {
            if (visibleSucursalsArray.includes(sucursal.value)) {
                if (sucursal.checked) {
                    sucursalsArrayAux.push(sucursal.value);
                }
                return { ...sucursal, visible: true };
            } else {
                return { ...sucursal, visible: false };
            }
        });

        if (persistentFilter !== "sucursal") {
            setSucursals(newSucursalsArray);
            setFilteredSucursals(visibleSucursalsArray);
        }

        // obtains sections in the report
        const visibleSectionsArray = getFilterValues(arrayAux, "section");
        let sectionsArrayAux: string[] = [];

        const newSectionsArray = sections.map(section => {
            if (visibleSectionsArray.includes(section.value)) {
                if (section.checked) {
                    sectionsArrayAux.push(section.value);
                }
                return { ...section, visible: true };
            } else {
                return { ...section, visible: false };
            }
        });

        if (persistentFilter !== "section") {
            setSections(newSectionsArray);
            setFilteredSections(visibleSectionsArray);
        }

        // filtramos nuevamente el reporte
        let newReport = arrayAux.filter((row: any) => {
            let [startDate] = row.createdComplete.split(",");
            let [finishDate] = row.finishedAt.split(",");

            return (
                displaysArrayAux.includes(row.name) &&
                datesArrayAux.includes(row.created) &&
                startDatesArrayAux.includes(startDate) &&
                finishDatesArrayAux.includes(finishDate) &&
                sucursalsArrayAux.includes(row.nameSucursal) &&
                sectionsArrayAux.includes(row.section)
            )
        });

        let finalReport: any[] = [];

        newSucursalsArray.forEach((sucursal: SearchFilterInterface) => {
            if (sucursal.visible) {

                const data = newReport.filter((row: any) => row.nameSucursal === sucursal.value);

                const newData = {
                    display: data[0].name,
                    created: data[0].created,
                    createdComplete: data[0].createdComplete,
                    finishedAt: data[0].finishedAt,
                    sucursal: sucursal.value,
                    data
                }

                finalReport.push(newData);
            }
        });

        setFilteredReport(finalReport);
    }, [totalFilteredSubsections])

    // state that help us to handle the pagination
    const [pagination, setPagination] = useState(1);

    const changePagination = (e: any, value: any) => {
        setPagination(value);
        window.scrollTo({
            top: 0,
            behavior: "auto"
        })
    }

    // function to get the report from the API
    useEffect(() => {
        getReport();
    }, []);

    return (
        <div>
            {
                isLoading ?
                    <div style={{ display: "grid", justifyContent: "center", alignContent: "center", height: "100vh" }}>
                        <CircularProgress sx={{ color: "#3b3b3b", fontWeight: "bold" }} />
                    </div>
                    :
                    <div id="main-container">
                        <NavbarDashboards clicked={clicked} handlerClick={handlerClick} dates={reportDates} />

                        <div id='main-section' className='w-full p-2 2xl:flex flex-row-reverse justify-between 2xl:p-0'>
                            <div id="filters-container" className='bg-fixed w-full bg-white flex flex-col justify-center mb-3 p-4 text-center 2xl:w-[20%] 2xl:mb-0 2xl:justify-start'>
                                <div className='w-full font-semibold mb-3 px-3 rounded-lg text-start'>
                                    <p>Filtros de búsqueda</p>
                                </div>

                                <SearchFiltersGondolaCold
                                    // displays
                                    displays={displays}
                                    selectedDisplays={filteredDisplays}
                                    setDisplays={setDisplays}
                                    setSelectedDisplays={setFilteredDisplays}
                                    setTotalSelectedDisplays={setTotalFilteredDisplays}

                                    // fecha de creación
                                    dates={dates}
                                    selectedDates={filteredDates}
                                    setDates={setDates}
                                    setSelectedDates={setFilteredDates}
                                    setTotalSelectedDates={setTotalFilteredDates}

                                    // fecha de inicio
                                    startDates={startDates}
                                    selectedStartDates={filteredStartDates}
                                    setStartDates={setStartDates}
                                    setSelectedStartDates={setFilteredStartDates}
                                    setTotalSelectedStartDates={setTotalFilteredStartDates}

                                    // fecha de finalización
                                    finishDates={finishDates}
                                    selectedFinishDates={filteredFinishDates}
                                    setFinishDates={setFinishDates}
                                    setSelectedFinishDates={setFilteredFinishDates}
                                    setTotalSelectedFinishDates={setTotalFilteredFinishDates}

                                    // sucursales
                                    sucursals={sucursals}
                                    selectedSucursals={filteredSucursals}
                                    setSucursals={setSucursals}
                                    setSelectedSucursals={setFilteredSucursals}
                                    setTotalSelectedSucursals={setTotalFilteredSucursals}

                                    // sección
                                    sections={sections}
                                    selectedSections={filteredSections}
                                    setSections={setSections}
                                    setSelectedSections={setFilteredSections}
                                    setTotalSelectedSections={setTotalFilteredSections}

                                    // subsección
                                    subsections={subsections}
                                    selectedSubsections={filteredSubsections}
                                    setSubsections={setSubsections}
                                    setSelectedSubsections={setFilteredSubsections}
                                    setTotalSelectedSubsections={setTotalFilteredSubsections}

                                    // utils para manejar los filtros de búsqueda
                                    persistenFilter={persistentFilter}
                                    setPersistentFilter={setPersistentFilter}
                                />
                            </div>

                            <div id='report-container' className='bg-white overflow-auto shadow hidden 2xl:block w-[68%]'>
                                <ReportTableGondolaCold report={filteredReport} pagination={pagination} changePagination={changePagination} />
                            </div>

                            <div id='cards-container' className='grid grid-cols-1 gap-4 2xl:hidden'>
                                <ReportCardGondolaCold report={filteredReport} pagination={pagination} changePagination={changePagination} />
                            </div>

                            <MenuOptionsDashboards clicked={clicked} gondola={true} orders={false} inventory={false} />
                        </div>
                    </div>
            }
        </div>
    );
}

export default DashboardGondolaCold