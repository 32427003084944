import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UseContextProvider from 'Interface/IAuthContext';


export const PrivateRoute = ( {children}: {children: any} ) => {

    const { authState } = useContext( UseContextProvider );
  
    return ( authState.logged )
        ? children
        : <Navigate to="/login" />
}