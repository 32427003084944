import Montain from "Media/AppBar/montain.png"
import ChanelGraphic from "Media/AppBar/line-chart-empty.png"
import BrandGraphic from "Media/AppBar/line-chart.png"
import NewPeople from "Media/AppBar/time.png"
import Aniversary from "Media/AppBar/balloon.png"
import Birthday from "Media/AppBar/birthday-cake.png"
import Message from "Media/AppBar/chat.png"

export const ItemAppBar = [
    {
        value: "0",
        icon: Montain,
        color: "#d2e1ff",
        title: "<h3>Alcance<br/>Metas</h3>"
    },{
        value: "1",
        icon: ChanelGraphic,
        color: "#d2e1ff",
        title: "<h3>Comparativa entre Canales</h3>"
    },{
        value: "2",
        icon: BrandGraphic,
        color: "#d2e1ff",
        title: "<h3>Ventas por<br/>Marcas</h3>"
    },{
        value: "3",
        icon: NewPeople,
        color: "#d8ffd2",
        title: "<h3>Nuevos<br/>Ingresos</h3>"
    },{
        value: "4",
        icon: Aniversary,
        color: "#d8ffd2",
        title: "<h3>Aniversarios</h3>"
    },{
        value: "5",
        icon: Birthday,
        color: "#d8ffd2",
        title: "<h3>Cumpleañeros</h3>"
    },{
        value: "6",
        icon: Birthday,
        color: "#d8ffd2",
        title: "<h3>Dedicatoria</h3>"
    },{
        value: "7",
        icon: Message,
        color: "#d8ffd2",
        title: "<h3>Informativo</h3>"
    }
    
]