import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import SearchFilter from 'Components/SearchFilter/SearchFilter';
import { SearchFilterInterface } from 'Interface';

const SearchFiltersGondolaCold = ({
    // displays
    displays,
    selectedDisplays,
    setDisplays,
    setSelectedDisplays,
    setTotalSelectedDisplays,

    // fecha de creación
    dates,
    selectedDates,
    setDates,
    setSelectedDates,
    setTotalSelectedDates,

    // fecha de inicio
    startDates,
    selectedStartDates,
    setStartDates,
    setSelectedStartDates,
    setTotalSelectedStartDates,

    // fecha de finalización
    finishDates,
    selectedFinishDates,
    setFinishDates,
    setSelectedFinishDates,
    setTotalSelectedFinishDates,

    // sucursales
    sucursals,
    selectedSucursals,
    setSucursals,
    setSelectedSucursals,
    setTotalSelectedSucursals,

    // sección
    sections,
    selectedSections,
    setSections,
    setSelectedSections,
    setTotalSelectedSections,

    // subsección
    subsections,
    selectedSubsections,
    setSubsections,
    setSelectedSubsections,
    setTotalSelectedSubsections,

    // utils para manejar los filtros de búsqueda
    persistenFilter,
    setPersistentFilter
}:

    {
        // displays
        displays: SearchFilterInterface[],
        selectedDisplays: string[],
        setDisplays: Dispatch<SetStateAction<SearchFilterInterface[]>>,
        setSelectedDisplays: Dispatch<SetStateAction<string[]>>,
        setTotalSelectedDisplays: Dispatch<SetStateAction<number>>,

        // fecha de creación
        dates: SearchFilterInterface[],
        selectedDates: string[],
        setDates: Dispatch<SetStateAction<SearchFilterInterface[]>>,
        setSelectedDates: Dispatch<SetStateAction<string[]>>,
        setTotalSelectedDates: Dispatch<SetStateAction<number>>,

        // fecha de inicio
        startDates: SearchFilterInterface[],
        selectedStartDates: string[],
        setStartDates: Dispatch<SetStateAction<SearchFilterInterface[]>>,
        setSelectedStartDates: Dispatch<SetStateAction<string[]>>,
        setTotalSelectedStartDates: Dispatch<SetStateAction<number>>,

        // fecha de finalización
        finishDates: SearchFilterInterface[],
        selectedFinishDates: string[],
        setFinishDates: Dispatch<SetStateAction<SearchFilterInterface[]>>,
        setSelectedFinishDates: Dispatch<SetStateAction<string[]>>,
        setTotalSelectedFinishDates: Dispatch<SetStateAction<number>>,

        // sucursales
        sucursals: SearchFilterInterface[],
        selectedSucursals: string[],
        setSucursals: Dispatch<SetStateAction<SearchFilterInterface[]>>,
        setSelectedSucursals: Dispatch<SetStateAction<string[]>>,
        setTotalSelectedSucursals: Dispatch<SetStateAction<number>>,

        // sección
        sections: SearchFilterInterface[],
        selectedSections: string[],
        setSections: Dispatch<SetStateAction<SearchFilterInterface[]>>,
        setSelectedSections: Dispatch<SetStateAction<string[]>>,
        setTotalSelectedSections: Dispatch<SetStateAction<number>>,

        // subsección
        subsections: SearchFilterInterface[],
        selectedSubsections: string[],
        setSubsections: Dispatch<SetStateAction<SearchFilterInterface[]>>,
        setSelectedSubsections: Dispatch<SetStateAction<string[]>>,
        setTotalSelectedSubsections: Dispatch<SetStateAction<number>>,

        // utils para manejar los filtros de búsqueda
        persistenFilter: 'display' | 'created' | 'createdComplete' | 'finishedAt' | 'sucursal' | 'section' | 'subsection' | "",
        setPersistentFilter: Dispatch<SetStateAction<'display' | 'created' | 'createdComplete' |
            'finishedAt' | 'sucursal' | 'section' | 'subsection' | "">>
    }) => {

    // loader
    const [isLoading, setIsLoading] = useState(true);

    // states that control the behavior of "ALL" button in the filters
    const [isAllDisplaysChecked, setIsAllDisplaysChecked] = useState(true);
    const [isAllDatesChecked, setIsAllDatesChecked] = useState(true);
    const [isAllStartDatesChecked, setIsAllStartDatesChecked] = useState(true);
    const [isAllFinishDatesChecked, setIsAllFinishDatesChecked] = useState(true);
    const [isAllSucursalsChecked, setIsAllSucursalsChecked] = useState(true);
    const [isAllSectionsChecked, setIsAllSectionsChecked] = useState(true);
    const [isAllSubsectionsChecked, setIsAllSubsectionsChecked] = useState(true);

    const handleAllSearchFiltersChange = (event: ChangeEvent<HTMLInputElement>, filters: SearchFilterInterface[], filterName: 'display' | 'created' | 'createdComplete' |
        'finishedAt' | 'sucursal' | 'section' | 'subsection',
        setIsAllFiltersChecked: (value: SetStateAction<boolean>) => void, setFilters: (value: SetStateAction<SearchFilterInterface[]>) => void,
        setSelectedFilters: (value: SetStateAction<string[]>) => void, setTotalSelectedFilters: (value: SetStateAction<number>) => void) => {
        let { checked } = event.target;

        let totalSelectedFilters: number = 0;
        let selectedFilters: string[] = [];

        const newFiltersArray = filters.map(filter => {
            if (filter.visible) {
                if (checked) {
                    totalSelectedFilters += 1;
                    selectedFilters.push(filter.value);
                }
            }

            return { ...filter, checked };
        })

        if (persistenFilter === "") {
            setPersistentFilter(filterName);
        } else {
            if ((checked && isAllDatesChecked && isAllSucursalsChecked) ||
                (isAllDisplaysChecked && checked && isAllSucursalsChecked) ||
                (isAllDisplaysChecked && isAllDatesChecked && checked)) {
                setPersistentFilter("");
            }
        }

        setIsAllFiltersChecked(checked);
        setFilters(newFiltersArray);
        setSelectedFilters(selectedFilters);
        setTotalSelectedFilters(totalSelectedFilters);
    }

    const handleSearchFilterChange = (event: ChangeEvent<HTMLInputElement>, filters: SearchFilterInterface[], filterName: 'display' | 'created' | 'createdComplete' |
        'finishedAt' | 'sucursal' | 'section' | 'subsection',
        setIsAllFiltersChecked: (value: SetStateAction<boolean>) => void, setFilters: (value: SetStateAction<SearchFilterInterface[]>) => void,
        setSelectedFilters: (value: SetStateAction<string[]>) => void, setTotalSelectedFilters: (value: SetStateAction<number>) => void) => {
        let { value, checked } = event.target;

        let totalSelectedFilters: number = 0;
        let totalVisibleFilters: number = 0;
        let selectedFilters: string[] = [];

        const newFiltersArray = filters.map(filter => {
            if (filter.value === value) {
                if (checked) {
                    totalSelectedFilters += 1;
                    selectedFilters.push(filter.value);
                }

                if (filter.visible) {
                    totalVisibleFilters += 1;
                }

                return { ...filter, checked: !filter.checked };
            }

            // si el checkbox está seleccionado y está visible, 
            // sumamos 1 al contador de filtros seleccionados
            if (filter.checked && filter.visible) {
                totalSelectedFilters += 1;
                selectedFilters.push(filter.value);
            }

            if (filter.visible) {
                totalVisibleFilters += 1;
            }

            return filter;
        });

        if (persistenFilter === "") {
            setPersistentFilter(filterName);
        } else {
            if ((totalSelectedFilters === filters.length && isAllDatesChecked && isAllSucursalsChecked) ||
                (isAllDisplaysChecked && totalSelectedFilters === filters.length && isAllSucursalsChecked) ||
                (isAllDisplaysChecked && isAllDatesChecked && totalSelectedFilters === filters.length)) {
                setPersistentFilter("");
            }
        }

        setFilters(newFiltersArray);
        setSelectedFilters(selectedFilters);
        setTotalSelectedFilters(totalSelectedFilters);
        setIsAllFiltersChecked(totalSelectedFilters === totalVisibleFilters ? true : false);
    }

    useEffect(() => {
        setIsLoading(false);
    })

    return (
        <>
            {
                isLoading ? <p>Cargando...</p> :
                    <div className='max-h-[400px] md:max-h-[500px] 2xl:max-h-screen overflow-y-scroll'>
                        {   /* -------------------------------------
                            --------------- DISPLAY ----------------
                            ---------------------------------------- */
                        }

                        <SearchFilter
                            label='Display'
                            persistentFilter={persistenFilter}
                            newValuePersistentFilter='display'
                            labelCheckboxAll='Todos'
                            filters={displays}
                            selectedFilters={selectedDisplays}
                            isAllFiltersChecked={isAllDisplaysChecked}
                            setIsAllFiltersChecked={setIsAllDisplaysChecked}
                            setFilters={setDisplays}
                            setSelectedFilters={setSelectedDisplays}
                            setTotalSelectedFilters={setTotalSelectedDisplays}
                            handleSearchFilterChange={handleSearchFilterChange}
                            handleAllSearchFiltersChange={handleAllSearchFiltersChange}
                        />
                        {   /* -------------------------------------
                            ----------- FECHA DE CREACIÓN ----------
                            ---------------------------------------- */
                        }

                        <SearchFilter
                            label='Fecha de creación'
                            persistentFilter={persistenFilter}
                            newValuePersistentFilter='created'
                            labelCheckboxAll='Todas'
                            filters={dates}
                            selectedFilters={selectedDates}
                            isAllFiltersChecked={isAllDatesChecked}
                            setIsAllFiltersChecked={setIsAllDatesChecked}
                            setFilters={setDates}
                            setSelectedFilters={setSelectedDates}
                            setTotalSelectedFilters={setTotalSelectedDates}
                            handleSearchFilterChange={handleSearchFilterChange}
                            handleAllSearchFiltersChange={handleAllSearchFiltersChange}
                        />

                        {   /* -------------------------------------
                            -------- FECHA DE INICIO DE SALA -------
                            ---------------------------------------- */
                        }

                        <SearchFilter
                            label='Fecha de inicio'
                            persistentFilter={persistenFilter}
                            newValuePersistentFilter='createdComplete'
                            labelCheckboxAll='Todas'
                            filters={startDates}
                            selectedFilters={selectedStartDates}
                            isAllFiltersChecked={isAllStartDatesChecked}
                            setIsAllFiltersChecked={setIsAllStartDatesChecked}
                            setFilters={setStartDates}
                            setSelectedFilters={setSelectedStartDates}
                            setTotalSelectedFilters={setTotalSelectedStartDates}
                            handleSearchFilterChange={handleSearchFilterChange}
                            handleAllSearchFiltersChange={handleAllSearchFiltersChange}
                        />

                        {   /* -------------------------------------
                            ---------- FECHA DE FIN DE SALA --------
                            ---------------------------------------- */
                        }

                        <SearchFilter
                            label='Fecha de finalización'
                            persistentFilter={persistenFilter}
                            newValuePersistentFilter='finishedAt'
                            labelCheckboxAll='Todas'
                            filters={finishDates}
                            selectedFilters={selectedFinishDates}
                            isAllFiltersChecked={isAllFinishDatesChecked}
                            setIsAllFiltersChecked={setIsAllFinishDatesChecked}
                            setFilters={setFinishDates}
                            setSelectedFilters={setSelectedFinishDates}
                            setTotalSelectedFilters={setTotalSelectedFinishDates}
                            handleSearchFilterChange={handleSearchFilterChange}
                            handleAllSearchFiltersChange={handleAllSearchFiltersChange}
                        />

                        {   /* -------------------------------------
                            --------------- SUCURSAL ---------------
                            ---------------------------------------- */
                        }

                        <SearchFilter
                            label='Sucursal'
                            persistentFilter={persistenFilter}
                            newValuePersistentFilter='sucursal'
                            labelCheckboxAll='Todas'
                            filters={sucursals}
                            selectedFilters={selectedSucursals}
                            isAllFiltersChecked={isAllSucursalsChecked}
                            setIsAllFiltersChecked={setIsAllSucursalsChecked}
                            setFilters={setSucursals}
                            setSelectedFilters={setSelectedSucursals}
                            setTotalSelectedFilters={setTotalSelectedSucursals}
                            handleSearchFilterChange={handleSearchFilterChange}
                            handleAllSearchFiltersChange={handleAllSearchFiltersChange}
                        />

                        {   /* -------------------------------------
                            ---------------- SECCIÓN ---------------
                            ---------------------------------------- */
                        }

                        <SearchFilter
                            label='Sección'
                            persistentFilter={persistenFilter}
                            newValuePersistentFilter='section'
                            labelCheckboxAll='Todas'
                            filters={sections}
                            selectedFilters={selectedSections}
                            isAllFiltersChecked={isAllSectionsChecked}
                            setIsAllFiltersChecked={setIsAllSectionsChecked}
                            setFilters={setSections}
                            setSelectedFilters={setSelectedSections}
                            setTotalSelectedFilters={setTotalSelectedSections}
                            handleSearchFilterChange={handleSearchFilterChange}
                            handleAllSearchFiltersChange={handleAllSearchFiltersChange}
                        />

                        {   /* -------------------------------------
                            --------------- SUBSECCIÓN -------------
                            ---------------------------------------- */
                        }

                        <SearchFilter
                            label='Subsección'
                            persistentFilter={persistenFilter}
                            newValuePersistentFilter='subsection'
                            labelCheckboxAll='Todas'
                            filters={subsections}
                            selectedFilters={selectedSubsections}
                            isAllFiltersChecked={isAllSubsectionsChecked}
                            setIsAllFiltersChecked={setIsAllSubsectionsChecked}
                            setFilters={setSubsections}
                            setSelectedFilters={setSelectedSubsections}
                            setTotalSelectedFilters={setTotalSelectedSubsections}
                            handleSearchFilterChange={handleSearchFilterChange}
                            handleAllSearchFiltersChange={handleAllSearchFiltersChange}
                        />
                    </div>
            }
        </>
    );
}

export default SearchFiltersGondolaCold;