const IconOrders = () => {
    return (
        <>
            <svg width="170" height="142" viewBox="0 0 170 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_254_282)">
                <path d="M169.987 101.76L168.975 98.9637L156.193 103.575L136.817 43.6115C135.736 40.7159 132.494 39.1987 129.568 40.23L121.744 43.0561L110.648 5.46383L110.619 5.36466C109.429 2.18156 105.859 0.505726 102.646 1.63617L52.7674 19.5944L48.077 4.58128L47.7894 3.66899L18.378 0L18.0111 2.95503L45.5286 6.39595L79.3825 114.82C74.3054 116.972 70.7355 121.999 70.7355 127.85C70.7355 135.654 77.0819 142 84.886 142C92.69 142 99.0364 135.654 99.0364 127.85C99.0364 127.681 99.0165 127.512 99.0066 127.344L169.987 101.76ZM93.4932 13.4166L79.5114 18.5532L77.7066 13.7835L91.8769 8.67668L93.4932 13.4166ZM74.9103 14.795L77.776 22.3709L97.2515 15.2115L94.6732 7.67514L103.647 4.44246C105.313 3.8574 107.178 4.7102 107.822 6.35628L118.948 44.0577L66.3427 63.0373L53.6698 22.4404L74.9202 14.7851L74.9103 14.795ZM116.3 55.9869L97.0135 62.948L94.4154 56.086L113.792 49.0951L116.3 55.9968V55.9869ZM67.2252 65.8832L91.6091 57.0876L95.2583 66.736L120.108 57.7718L116.588 48.0737L130.57 43.0264C131.968 42.5306 133.515 43.2644 134.001 44.5832L153.387 104.576L98.5703 124.339C97.0036 118.231 91.4703 113.709 84.886 113.709C83.9836 113.709 83.0911 113.798 82.2383 113.967L67.2252 65.8932V65.8832ZM84.886 139.025C78.728 139.025 73.7104 134.017 73.7104 127.85C73.7104 121.682 78.7181 116.674 84.886 116.674C91.0538 116.674 96.0615 121.682 96.0615 127.85C96.0615 134.017 91.0538 139.025 84.886 139.025Z" fill="#183555"/>
                <path d="M25.3689 45.1881H7.40076V48.163H25.3689V45.1881Z" fill="#183555"/>
                <path d="M44.0709 65.5263H19.0523V68.5011H44.0709V65.5263Z" fill="#183555"/>
                <path d="M39.7672 83.5043H0.0131836V86.4792H39.7672V83.5043Z" fill="#183555"/>
                </g>
                <defs>
                <clipPath id="clip0_254_282">
                <rect width="169.974" height="142" fill="white" transform="translate(0.0131836)"/>
                </clipPath>
                </defs>
            </svg>
        </>
    );


}

export default IconOrders;