import { useEffect, useState } from "react";
import ReactEcharts from 'echarts-for-react';

interface PropsInterface {
    data: any
    allData: any
}
const TabOne = (props: PropsInterface) => {
    const [data, setData] = useState<any>([])
    const [dataTable, setDataTable] = useState<any>([])
    const [loader, setLoader] = useState(true)
    const [option, setOption] = useState<any>(null)

    useEffect(() => {
        setData(() => props.data)
    }, [props.data])

    useEffect(() => {
        setDataTable(() => props.allData.sort((a: any, b: any) => b.cumplimiento - a.cumplimiento))
    }, [props.allData])

    useEffect(() => {
        if (dataTable.length > 0) onLoad()
    }, [dataTable])

    useEffect(() => {
        if (data === undefined || data.length === 0) setLoader(true)
        else {
            setOption(() => {
                return {
                    series: [
                        {
                            data: [
                                {
                                    value: data[1][1] ?? 0,
                                    name: '%'
                                }
                            ],
                            type: 'gauge',
                            startAngle: 180,
                            endAngle: 0,
                            min: 0,
                            max: 100,
                            splitNumber: 3,
                            pointer: {
                                icon: 'circle',
                                length: '12%',
                                width: 50,
                                offsetCenter: [0, '-90%'],
                                itemStyle: {
                                    color: '#FFFFFF',
                                    borderColor: 'auto', // This doesn't work :(
                                    borderWidth: 5,
                                    shadowColor: 'rgba(10, 31, 68, 0.5)',
                                    shadowBlur: 2,
                                    shadowOffsetY: 1,
                                },
                            },
                            axisLine: {
                                show: true,
                                roundCap: true,
                                lineStyle: {
                                    width: 9,
                                    color: [
                                        [0.5, '#e76262'],
                                        [0.54],
                                        [0.66, '#f9cf4a'],
                                        [0.7],
                                        [0.83, '#eca336'],
                                        [0.87],
                                        [1, '#3ece80'],
                                    ],
                                },
                            },
                            axisTick: {
                                length: 2,
                                lineStyle: {
                                    color: '#8a94a6',
                                    width: 2,
                                },
                            },
                            splitLine: {
                                show: false,
                            },
                            axisLabel: {
                                show: false,
                            },
                            title: {
                                show: false,
                            },
                            detail: {
                                rich: {
                                    header: {
                                        fontSize: 36,
                                        fontWeight: 700,
                                        fontFamily: 'Open Sans',
                                        color: '#0a1f44',
                                    },
                                    subHeader: {
                                        fontSize: 16,
                                        fontWeight: 400,
                                        fontFamily: 'Open Sans',
                                        color: '#8a94a6',
                                    },
                                },
                                offsetCenter: [0, '-20%'],
                                valueAnimation: true,
                            },
                        },
                    ],
                }
            })
            setLoader(false)
        }
        // eslint-disable-next-line
    }, [data])


    const onLoad = () => {
        let dataCopy = dataTable
        for (let i = 0; i < dataCopy.length; i++) {
            dataCopy[i].sold = formatNumber(isNaN(Number(dataCopy[i].sold)) ? dataCopy[i].sold : Number(dataCopy[i].sold).toFixed(2))
            dataCopy[i].meta = formatNumber(isNaN(Number(dataCopy[i].meta)) ? dataCopy[i].meta : Number(dataCopy[i].meta).toFixed(2))

        }
        setDataTable(dataCopy)
    }
    const formatNumber = (nStr: string) => {
        nStr += '';
        let x = nStr.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            // eslint-disable-next-line
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    }

    const colorTable = (value: number) => {
        if (value >= 0 && value <= 50) return "#e76262"
        if (value > 50 && value <= 70) return "#f9cf4a"
        if (value > 70 && value <= 80) return "#eca336"
        if (value > 70 && value <= 100) return "#3ece80"
        else return "#3ece80"
    }

    return (
        <>
            <div className="section-one">
                {
                    loader ?
                        <p> cargando... </p>
                        :
                        <div className="graph-container">
                            <div className="card-container">
                                {
                                    option !== null &&
                                    <ReactEcharts
                                        option={option}
                                        className="e-chart"
                                        style={{ height: '400px', width: '600px', padding: 0, margin: 0 }} />
                                }
                                <div className="prueba">
                                    <h3 style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>Cumplimiento de la meta</h3>
                                    <h2 style={{ fontSize: "2rem" }}>{data[1]?.[1] ?? 0}% / 100%</h2>
                                </div>
                            </div>
                        </div>
                }
                <div className="card-root">
                    <div className="table-base">
                        <div className="row">
                            <h3 style={{ fontWeight: "bold" }}>Meta</h3>
                            <h3>% Cumpliemiento</h3>
                        </div>
                        <>
                            {
                                dataTable?.map((row: any, index: number) => (
                                    <div className="row" key={index}>
                                        <p>{row.vendor.toUpperCase()}</p>
                                        <p style={{ color: colorTable(row.cumplimiento), fontWeight: "bold" }}>{row.cumplimiento}%</p>
                                    </div>
                                ))
                            }
                        </>
                    </div>
                </div>
            </div>
        </>

    )
}
export default TabOne;