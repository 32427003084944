import Chart from "react-google-charts";
import { Colors } from "Components/Data/Colors";

interface PropsInterface {
    dataCiruclar: any
    dataTable: any
}
const TabThree = (props: PropsInterface) => {
    return (
        <>
            <div className="section-one ">
                <div className="graph-container">
                    <div className="card-container pie-chart">
                        {
                            props.dataCiruclar.length > 0 && 
                                <Chart
                                    width={"100%"}
                                    height={440}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={props.dataCiruclar}
                                    options={{
                                        legend: "none",
                                        chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
                                        pieHole: 0.5,
                                        pieSliceText: "label",
                                        colors: Colors,
                                    }}
                                    rootProps={{ "data-testid": "1" }}
                                    chartWrapperParams={{ view: { columns: [0, 1] } }}
                                    chartPackages={["corechart", "controls"]}
                                />
                        }
                    </div>
                </div>
                <div className="card-root">
                <div className="table-base">
                        <div className="row">
                            <h3>Marca</h3>
                            <h3>Cantidades (Unidades)</h3>
                        </div>
                        {
                            props.dataTable?.map((item: any, index: number) => {
                                return (
                                    <div key={index} style={{ backgroundColor: index % 2 !== 0 ? "#f9f9f9" : "#FFF", borderRadius: "5px"}}>
                                        <div className="row-three" >
                                            <div className="icon">
                                                <div className="drawable" style={{ backgroundColor: Colors[index]}}></div>
                                                <p>{item.marca.toUpperCase()}</p>
                                            </div>
                                            <p>{item.sold}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default TabThree;