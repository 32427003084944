import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { getFilterValues, getUserToken } from 'Utils/UtilsDashboards';
import NavbarDashboards from "Components/NavbarDashboards/NavbarDashboards";
import MenuOptionsDashboards from "Components/MenuOptionsDashboards/MenuOptionsDashboards";
import ReportTableOrders from 'Components/DashboardOrders/ReportTableOrders';
import ReportCardOrders from 'Components/DashboardOrders/ReportCardOrders';
import { getOrdersReport } from 'Services/OrdersService';
import SearchFiltersOrders from 'Components/DashboardOrders/SearchFiltersOrders';
import { SearchFilterInterface } from 'Interface';

const DashboardOrders = () => {
    // states that help us to show the report
    const [report, setReport] = useState<any[]>([]);
    const [filteredReport, setFilteredReport] = useState<any[]>([]);
    const [reportDates, setReportDates] = useState("");

    // estados que nos ayudarán a preservar el checked de los checkbox cuando se elimine o se agregue
    // algún otro checkbox
    const [displays, setDisplays] = useState<SearchFilterInterface[]>([]);
    const [dates, setDates] = useState<SearchFilterInterface[]>([]);
    const [sucursals, setSucursals] = useState<SearchFilterInterface[]>([]);

    // states that help us to filter the report
    const [filteredDisplays, setFilteredDisplays] = useState<string[]>([]);
    const [filteredDates, setFilteredDates] = useState<string[]>([]);
    const [filteredSucursals, setFilteredSucursals] = useState<string[]>([]);

    const [persistentFilter, setPersistentFilter] = useState<'display' | 'created' | 'createdComplete' |
    'finishedAt' | 'sucursal' | 'section' | 'subsection' | "">("");

    // loader
    const [isLoading, setIsLoading] = useState(true);

    // state that help us to know if we have to show the hamburguer menu option
    const [clicked, setClicked] = useState(false);

    const handlerClick = () => {
        setClicked(!clicked);
    }

    // function to obtain the report from the API and filter's options
    const getReport = async () => {
        // obtains user's token
        const userToken = getUserToken();

        // obtains orders report
        const report = await getOrdersReport(userToken);
        setReport(report);
        setFilteredReport(report);

        // obtains display's name in the report
        const visibleDisplaysArray = getFilterValues(report, "name");

        let displaysArray: SearchFilterInterface[] = [];

        visibleDisplaysArray.forEach((displayValue: string) => {
            let display: SearchFilterInterface = {
                value: displayValue,
                visible: true,
                checked: true
            }

            displaysArray.push(display);
        })

        setDisplays(displaysArray);
        setFilteredDisplays(visibleDisplaysArray);

        // obtains dates in the report
        const visibleDatesArray = getFilterValues(report, "created");

        let datesArray: SearchFilterInterface[] = [];

        visibleDatesArray.forEach((dateValue: string) => {
            let date: SearchFilterInterface = {
                value: dateValue,
                visible: true,
                checked: true
            }

            datesArray.push(date);
        })

        setDates(datesArray);
        setFilteredDates(visibleDatesArray);

        // obtains sucursals in the report
        const visibleSucursalsArray = getFilterValues(report, "nameSucursal");

        let sucursalsArray: SearchFilterInterface[] = [];

        visibleSucursalsArray.forEach((sucursalValue: string) => {
            let sucursal: SearchFilterInterface = {
                value: sucursalValue,
                visible: true,
                checked: true
            }

            sucursalsArray.push(sucursal);
        })

        setSucursals(sucursalsArray);
        setFilteredSucursals(visibleSucursalsArray);

        const reportDates = "Del " + datesArray[0].value + " al " + datesArray[datesArray.length - 1].value;
        setReportDates(reportDates);

        // set the value of false to the variable that controls the loading screen 
        setIsLoading(false);
    }

    // states that help us to know if we have to do any filtering
    const [totalFilteredDisplays, setTotalFilteredDisplays] = useState(-1);
    const [totalFilteredDates, setTotalFilteredDates] = useState(-1);
    const [totalFilteredSucursals, setTotalFilteredSucursals] = useState(-1);

    // functions to filter the report
    useEffect(() => {
        let arrayAux = report.filter((row: any) =>
            filteredDisplays.includes(row.name)
        );

        // obtains dates in the report
        const visibleDatesArray = getFilterValues(arrayAux, "created");
        let createdArrayAux: string[] = [];

        const newDatesArray = dates.map(date => {
            if (visibleDatesArray.includes(date.value)) {
                if (date.checked) {
                    createdArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "created") {
            setDates(newDatesArray);
            setFilteredDates(visibleDatesArray);
        }

        // obtains sucursals in the report
        const visibleSucursalsArray = getFilterValues(arrayAux, "nameSucursal");
        let sucursalsArrayAux: string[] = [];

        const newSucursalsArray = sucursals.map(sucursal => {
            if (visibleSucursalsArray.includes(sucursal.value)) {
                if (sucursal.checked) {
                    sucursalsArrayAux.push(sucursal.value);
                }
                return { ...sucursal, visible: true };
            } else {
                return { ...sucursal, visible: false };
            }
        });

        
        if (persistentFilter !== "sucursal") {
            setSucursals(newSucursalsArray);
            setFilteredSucursals(visibleSucursalsArray);
        }

        // filtramos nuevamente el reporte
        let newReport = arrayAux.filter((row: any) =>
            sucursalsArrayAux.includes(row.nameSucursal) &&
            createdArrayAux.includes(row.created)
        );

        setFilteredReport(newReport);
    }, [totalFilteredDisplays])

    useEffect(() => {
        let arrayAux = report.filter((row: any) =>
            (filteredDates.includes(row.created))
        );

        // obtains displays in the report
        const visibleDisplaysArray = getFilterValues(arrayAux, "name");
        let displaysArrayAux: string[] = [];

        const newDisplaysArray = displays.map(display => {
            if (visibleDisplaysArray.includes(display.value)) {
                if (display.checked) {
                    displaysArrayAux.push(display.value);
                }
                return { ...display, visible: true };
            } else {
                return { ...display, visible: false };
            }
        });

        if (persistentFilter !== "display") {
            setDisplays(newDisplaysArray);
            setFilteredDisplays(visibleDisplaysArray);
        }

        // obtains sucursals in the report
        const visibleSucursalsArray = getFilterValues(arrayAux, "nameSucursal");
        let sucursalsArrayAux: string[] = [];

        const newSucursalsArray = sucursals.map(sucursal => {
            if (visibleSucursalsArray.includes(sucursal.value)) {
                if (sucursal.checked) {
                    sucursalsArrayAux.push(sucursal.value);
                }
                return { ...sucursal, visible: true };
            } else {
                return { ...sucursal, visible: false };
            }
        });

        
        if (persistentFilter !== "sucursal") {
            setSucursals(newSucursalsArray);
            setFilteredSucursals(visibleSucursalsArray);
        }

        // filtramos nuevamente el reporte
        let newReport = arrayAux.filter((row: any) =>
            displaysArrayAux.includes(row.name) &&
            sucursalsArrayAux.includes(row.nameSucursal)
        );

        setFilteredReport(newReport);
    }, [totalFilteredDates])

    useEffect(() => {
        let arrayAux = report.filter((row: any) =>
            (filteredSucursals.includes(row.nameSucursal))
        );

        // obtains displays in the report
        const visibleDisplaysArray = getFilterValues(arrayAux, "name");
        let displaysArrayAux: string[] = [];

        const newDisplaysArray = displays.map(display => {
            if (visibleDisplaysArray.includes(display.value)) {
                if (display.checked) {
                    displaysArrayAux.push(display.value);
                }
                return { ...display, visible: true };
            } else {
                return { ...display, visible: false };
            }
        });

        if (persistentFilter !== "display") {
            setDisplays(newDisplaysArray);
            setFilteredDisplays(visibleDisplaysArray);
        }

        // obtains dates in the report
        const visibleDatesArray = getFilterValues(arrayAux, "created");
        let createdArrayAux: string[] = [];

        const newDatesArray = dates.map(date => {
            if (visibleDatesArray.includes(date.value)) {
                if (date.checked) {
                    createdArrayAux.push(date.value);
                }
                return { ...date, visible: true };
            } else {
                return { ...date, visible: false };
            }
        });

        if (persistentFilter !== "created") {
            setDates(newDatesArray);
            setFilteredDates(visibleDatesArray);
        }

        // filtramos nuevamente el reporte
        let newReport = arrayAux.filter((row: any) =>
            displaysArrayAux.includes(row.name) &&
            createdArrayAux.includes(row.created)
        );

        setFilteredReport(newReport);
    }, [totalFilteredSucursals])

    // state that help us to handle the pagination
    const [pagination, setPagination] = useState(1);

    const changePagination = (e: any, value: any) => {
        setPagination(value);
        window.scrollTo({
            top: 0,
            behavior: "auto"
        })
    }

    useEffect(() => {
        getReport();
    }, [])

    return (
        <>
            {
                isLoading ?
                    <div style={{ display: "grid", justifyContent: "center", alignContent: "center", height: "100vh" }}>
                        <CircularProgress sx={{ color: "#3b3b3b", fontWeight: "bold" }} />
                    </div>
                    :
                    <div id="main-container">
                        <NavbarDashboards clicked={clicked} handlerClick={handlerClick} dates={reportDates} />

                        <div id='main-section' className='w-full p-2 2xl:flex flex-row-reverse justify-between 2xl:p-0'>
                            <div id="filters-container" className='bg-fixed w-full bg-white flex flex-col justify-center mb-3 p-4 text-center 2xl:w-[20%] 2xl:mb-0 2xl:justify-start'>
                                <div className='w-full font-semibold mb-3 px-3 rounded-lg text-start'>
                                    <p>Filtros de búsqueda</p>
                                </div>

                                <SearchFiltersOrders
                                    // displays
                                    displays={displays}
                                    selectedDisplays={filteredDisplays}
                                    setDisplays={setDisplays}
                                    setSelectedDisplays={setFilteredDisplays}
                                    setTotalSelectedDisplays={setTotalFilteredDisplays}

                                    // fecha
                                    dates={dates}
                                    selectedDates={filteredDates}
                                    setDates={setDates}
                                    setSelectedDates={setFilteredDates}
                                    setTotalSelectedDates={setTotalFilteredDates}

                                    // sucursales
                                    sucursals={sucursals}
                                    selectedSucursals={filteredSucursals}
                                    setSucursals={setSucursals}
                                    setSelectedSucursals={setFilteredSucursals}
                                    setTotalSelectedSucursals={setTotalFilteredSucursals}

                                    // utils para manejar el estado de los filtros
                                    persistenFilter={persistentFilter}
                                    setPersistentFilter={setPersistentFilter}
                                />
                            </div>

                            <div id='report-container' className='bg-white overflow-auto shadow hidden 2xl:block w-[68%]'>
                                <ReportTableOrders report={filteredReport} pagination={pagination} changePagination={changePagination} />
                            </div>

                            <div id='cards-container' className='grid grid-cols-1 gap-4 2xl:hidden'>
                                <ReportCardOrders report={filteredReport} pagination={pagination} changePagination={changePagination} />
                            </div>

                            <MenuOptionsDashboards clicked={clicked} gondola={false} orders={true} inventory={false} />
                        </div>
                    </div>
            }
        </>
    )
}

export default DashboardOrders;