import { useNavigate } from "react-router-dom";
import styled from 'styled-components';

const MenuOptionsDashboards = ( {clicked, gondola, orders, inventory}: {clicked: boolean, gondola: boolean, orders: boolean, inventory: boolean} ) => {
    // navigate
    const navigate = useNavigate();

    return (
        <>
            <MenuContainer className={`bg-white [&>p]:pl-8 [&>p]:w-full [&>p]:mr-4 [&>p]:p-2 [&>p:hover]:cursor-pointer links md:w-[12%] md:flex md:flex-col ${clicked ? 'active' : ''}`}>
                <p onClick={() => navigate("/dashboardGondolaFrio")} className={`${gondola ? 'selected' : 'white'}`}>Góndola y frío</p>
                <p onClick={() => navigate("/dashboardPedidos")} className={`${orders ? 'selected' : 'white'}`}>Pedidos</p>
                <p onClick={() => navigate("/dashboardInventario")} className={`${inventory ? 'selected' : 'white'}`}>Inventario</p>
                <p onClick={() => navigate("/menu")} className={`white`}>Menú principal</p>
            </MenuContainer>
        </>
    )
}

export default MenuOptionsDashboards;

const MenuContainer = styled.div`
position: absolute;
    top: -700px;
    left: -2000px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    transition: all .5s ease;
    
    p{
        font-size: 2rem;
        display: block;
        font-weight: 500;
    }

    p:hover{
        background: #ECFCCB;
        color: #000;
    }

    p.selected{
        background-color: #EFF6FF;
        font-weight: bold;
        color: #1E3A8A;

        :hover{
            background: #ECFCCB;
            color: #000;
        }
    }

    @media(min-width: 1536px){
        position: initial;
        margin: 0;
        p{
            text-align: start;
            font-size: 1.2rem;
            display: inline;
        }
    }

&.active{
    width: 80%;
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    transition: all .6s ease ;
    top: 30%;
    left: 0;
    right: 0;
    z-index: 2;
    background: none;
    text-align: center;
    p{
        font-size: 2rem;
        margin-top: 1rem;
    }

    .white{
        color: #fff;

        :hover{
            color: #000;
        }
    }
  }
`