import { useReducer } from 'react';
import UseContextProvider, { AuthContextModel, PropsInterface } from 'Interface/IAuthContext';
import { authReducer } from './AuthReducer';
import { types } from 'Types/Types';

const init = () => {
    const user = JSON.parse(localStorage.getItem('user')!);

    return {
        logged: !!user,
        user: user?.token ?? null, 
        role: user?.role ?? null
    }
}

const AuthContextProvider = ( props: PropsInterface ) => {
    
    const[authState, dispatch] = useReducer( authReducer, {}, init );
    
    const login = ( email: string, token: string, role:string) => {
        const user = {
            email: email,
            token: token,
            role: role
        }
        
        const action = {
            type: types.login,
            payload: user
        }

        localStorage.setItem('user', JSON.stringify(user));

        dispatch(action);
    }

    const logout = () => {
        localStorage.removeItem('user');
        const action = { type: types.logout };
        dispatch(action);
    }

    const authContext: AuthContextModel = {
        // Attributes
        authState,

        // Methods
        login,
        logout
    }

    return (
        <UseContextProvider.Provider value={authContext}>
            { props.children }
        </UseContextProvider.Provider>
    )
}

export default AuthContextProvider;