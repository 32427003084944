import { useNavigate } from "react-router-dom";
import InconDynamicDashboard from "Media/Icons/IconDynamicDashboard";
import IconGondolaCold from "Media/Icons/IconGondolaCold";
import IconOrders from "Media/Icons/IconOrders";
import IconInventory from "Media/Icons/IconInventory";
import NavbarMenu from "Components/NavbarMenu/NavbarMenu";

const MenuDashboards = () => {
    const navigate = useNavigate();

    return (
        <>
            <NavbarMenu />
            <div 
                className="w-full h-screen px-4 bg-gray-200 grid grid-cols-3 gap-4 px-2 py-4 [&>div:hover]:bg-blue-100 [&>div]:flex [&>div]:flex-col [&>div]:justify-center [&>div]:items-center [&>div]:text-center [&>div]:bg-white [&>div]:p-4 [&>div:hover]:cursor-pointer [&>div]:rounded-lg">
                <div id="dashboardDinamyc" onClick={() => navigate("/dashboard")} className="mb-3">
                    <InconDynamicDashboard />
                    <p className="text-[#183555] text-2xl font-bold mt-5">Dashboard dinámico</p>
                </div>

                <div id="dashboardDinamyc" onClick={() => navigate("/dashboardGondolaFrio")} className="mb-3">
                    <IconGondolaCold />
                    <p className="text-[#183555] text-2xl font-bold mt-5">Góndola y frío</p>
                </div>

                <div id="dashboardDinamyc" onClick={() => navigate("/dashboardPedidos")} className="mb-3">
                    <IconOrders />
                    <p className="text-[#183555] text-2xl font-bold mt-5">Pedidos</p>
                </div>

                <div id="dashboardDinamyc" onClick={() => navigate("/dashboardInventario")}>
                    <IconInventory />
                    <p className="text-[#183555] text-2xl font-bold mt-5">Inventario</p>
                </div>
                
                <div id="dashboardDinamyc" onClick={() => navigate("/vendor/control")}>
                    <IconInventory />
                    <p className="text-[#183555] text-2xl font-bold mt-5">Cumplimiento de vendedores</p>
                </div>
            </div>
        </>
    )
}

export default MenuDashboards;