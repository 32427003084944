// function to obtain the displays by date
export const getDisplaysByDate = (report: any, date: any, dates: any, indexDate: number) => {
    let arrayAux: any = [];
    let quantityDate: number = 0;
    report.forEach((item: any) => {
        if (item.created === date) {
            if (item.quantityProduct !== "") quantityDate += item.quantityProduct;
            arrayAux.push(item.name);
        }
    })

    let newDate = {
        value: date,
        quantityDate: quantityDate
    }

    dates[indexDate] = newDate;

    // we filter the arrayAux to get only one match of each record
    let filterValues = arrayAux.filter((item: any, index: number) => {
        return arrayAux.indexOf(item) === index;
    })

    return filterValues;
}

// function to obtain the sucursals by every display in every date
export const getSucursalByDateAndDisplay = (report: any, date: any, displays: any) => {
    let arrayAux: any = [];

    // recorremos los displays
    displays.forEach((display: any, indexDisplay: number) => {
        let arraySucursalsByDisplay: any = [];
        let quantityDisplay: number = 0;
        report.forEach((item: any) => {
            // por cada registro del reporte comparamos la fecha de creación y el display
            if (item.created === date.value && item.name === display) {
                if (item.quantityProduct !== "") quantityDisplay += item.quantityProduct;
                arraySucursalsByDisplay.push(item.nameSucursal); // obstains the sucursal
            }
        })

        let newDisplay = {
            name: display,
            quantity: quantityDisplay
        }

        displays[indexDisplay] = newDisplay;

        // we filter the arrayAux to get only one match of each record
        let filterValues = arraySucursalsByDisplay.filter((item: any, index: number) => {
            return arraySucursalsByDisplay.indexOf(item) === index;
        })

        arrayAux.push(filterValues);
    })

    return arrayAux;
}

// function to obtain the products by every sucursal for every display in every date
export const getProductsByDateAndDisplayAndSucursal = (report: any, date: any, displays: any, sucursals: any) => {
    let arrayAux: any = [];

    // recorremos el arreglo de displays
    displays.forEach((display: any, indexDisplay: number) => {
        let productsByDisplayAndSucursal: any = [];
        // recorremos el arreglo de sucursales
        sucursals.forEach((sucursalsByDisplay: any, indexSucursalByDisplay: number) => {
            // tomamos en cuenta solo las sucursales del display en cuestión
            if (indexSucursalByDisplay === indexDisplay) {
                // recorremos cada sucursal del display
                sucursalsByDisplay.forEach((sucursal: any, indexSucursal: number) => {
                    let productsBySucursal: any = [];
                    let quantitySucursal: number = 0;
                    report.forEach((item: any) => {
                        // por cada registro del reporte comparamos si coincide la fecha, display y sucursal
                        if (item.created === date.value && item.name === display.name && item.nameSucursal === sucursal) {
                            // obtenemos los productos                                                          
                            let product = {
                                name: item.nameProduct === "" ? "NO ES DÍA DE INVENTARIO" : item.nameProduct,
                                quantity: item.quantityProduct === "" ? 0 : item.quantityProduct
                            }
                            if (item.quantityProduct !== "") quantitySucursal += item.quantityProduct;
                            productsBySucursal.push(product);
                        }

                    })
                    productsByDisplayAndSucursal.push(productsBySucursal);
                    let newSucursal = {
                        name: sucursal,
                        quantity: quantitySucursal
                    }
                    sucursalsByDisplay[indexSucursal] = newSucursal;
                })
            }
        })

        arrayAux.push(productsByDisplayAndSucursal);
    })

    return arrayAux;
}