import axios from "axios";

export const getOrdersReport = async ( token: string ) => {
    let report: string[] = [];

    await axios.get(`${process.env.REACT_APP_URL}/benchmark/get/report/orders?days=15`, {
        headers:{
            // Header with the auth for accesing the report
            "Authorization": `UserToken ${token}`
        }
    }).then((response) => {
        report = response.data;
    }).catch((error) => {
        console.log(error.message);
        console.log(error.response?.data);
    });

    return report;
};