const InconDynamicDashboard = () => {
    return (
        <>
            <svg width="188" height="142" viewBox="0 0 188 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_240_192)">
                <path d="M143.086 46.5413H127.433V123.762H143.086V46.5413Z" fill="#183555"/>
                <path d="M143.704 46.5413H128.051V123.762H143.704V46.5413Z" fill="#183555"/>
                <path d="M178.59 142H9.41006C4.62589 142 0.740753 138.107 0.740753 133.331V8.66931C0.740753 3.89316 4.63391 0 9.41006 0H178.59C183.374 0 187.259 3.89316 187.259 8.66931V133.323C187.259 138.107 183.366 141.992 178.59 141.992V142ZM9.41006 2.20746C5.84601 2.20746 2.94822 5.10526 2.94822 8.66931V133.323C2.94822 136.887 5.84601 139.793 9.41809 139.793H178.598C182.162 139.793 185.068 136.895 185.068 133.323V8.66931C185.068 5.10526 182.17 2.19943 178.598 2.19943H9.41006V2.20746Z" fill="#183555"/>
                <path d="M41.7434 46.4209H69.8945C69.8945 61.8651 57.1876 74.5721 41.7434 74.5721C26.2991 74.5721 13.5922 61.8651 13.5922 46.4209C13.5922 30.9767 26.2991 18.2617 41.7434 18.2617C44.6492 18.2617 47.5389 18.7112 50.3003 19.5942L41.7434 46.4129V46.4209Z" fill="#183555"/>
                <path d="M48.2935 41.709L57.0912 14.9626C62.6942 16.8088 67.5827 20.3648 71.0584 25.141C74.5342 29.9091 76.4206 35.6565 76.4446 41.5565L48.2935 41.709Z" fill="#183555"/>
                <path d="M121.429 88.8684H105.776V123.77H121.429V88.8684Z" fill="#183555"/>
                <path d="M164.735 73.1192H149.082V123.762H164.735V73.1192Z" fill="#183555"/>
                <path d="M88.7181 6.98361H86.31V134.238H88.7181V6.98361Z" fill="#183555"/>
                <path d="M73.9803 90.2812H22.3337V92.6893H73.9803V90.2812Z" fill="#183555"/>
                <path d="M73.9803 105.533H22.3337V107.941H73.9803V105.533Z" fill="#183555"/>
                <path d="M73.9803 120.784H22.3337V123.192H73.9803V120.784Z" fill="#183555"/>
                </g>
                <defs>
                <clipPath id="clip0_240_192">
                <rect width="186.518" height="142" fill="white" transform="translate(0.740753)"/>
                </clipPath>
                </defs>
            </svg>
        </>
    );
}

export default InconDynamicDashboard;